import axios from "axios";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 20000,
});

instance.interceptors.request.use(
  (config) => {
    console.log(process.env);
    // config.headers.common["Authorization"] = cookies.get("token");
    config.headers["x-access-token"] = cookies.get("token");
    console.log("axios.js request : ", config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
