<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Dashboards"
          :collapse="false"
          :to="{ name: 'Default' }"
          :aria-controls="''"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
          collapse-ref="/dashboards"
        >
          <template #icon>
            <Shop />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="상품관리"
          :class="getRoute() === 'product' ? 'active' : ''"
        >
          <template #icon>
            <Office />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Product Stock' }"
                mini-icon="P"
                text="상품 재고 현황"
              />
              <sidenav-item
                :to="{ name: 'Product Stock New' }"
                mini-icon="P"
                text="상품 재고 현황 New"
              />
              <sidenav-item
                :to="{ name: 'Esim List' }"
                mini-icon="W"
                text="eSim 개별 현황"
              />
              <sidenav-item
                :to="{ name: 'Esim Stock' }"
                mini-icon="C"
                text="상품 재고 관리"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples1"
          nav-text="주문관리"
          :class="getRoute() === 'order' ? 'active' : ''"
        >
          <template #icon>
            <Basket />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Cafe24 Order List(eSim)' }"
                mini-icon="P"
                text="Cafe24 주문 현황(eSim)"
              />
              <sidenav-item
                :to="{ name: 'Cafe24 Order List(uSim)' }"
                mini-icon="P"
                text="Cafe24 주문 현황(uSim/WIFI)"
              />
              <sidenav-item
                :to="{ name: 'Cafe24 Order List' }"
                mini-icon="P"
                text="Cafe24 주문 현황(통합)"
              />
              <sidenav-item
                :to="{ name: 'GetYourGuide Order List' }"
                mini-icon="P"
                text="GYG 주문 현황"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples3"
          nav-text="유심스토어 관리자"
          :class="getRoute() === 'usimStore' ? 'active' : ''"
        >
          <template #icon>
            <!-- <Usimstore /> -->
            <img src="../../assets/img/esim/usimstore_logo.svg" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'UsimStore Product List' }"
                mini-icon="P"
                text="유심스토어 상품코드"
              />
              <sidenav-item
                :to="{ name: 'UsimStore Order List' }"
                mini-icon="P"
                text="유심스토어 주문관리"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples2"
          nav-text="설정"
          :class="getRoute() === 'setting' ? 'active' : ''"
        >
          <template #icon>
            <Settings />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Admin Lists' }"
                mini-icon="P"
                text="사용자관리"
              />
              <sidenav-item
                :to="{ name: 'Partner Lists' }"
                mini-icon="P"
                text="파트너관리"
              />
              <!-- <sidenav-item
                :to="{ name: 'Error Error404' }"
                mini-icon="W"
                text="Cafe24 API 설정"
              /> -->
              <!-- <sidenav-item
                :to="{ name: 'Error Error404' }"
                mini-icon="C"
                text="CMLink API 설정"
              /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      text-primary="Need Help?"
      text-secondary="Please check our docs"
      route="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/"
      label="Documentation"
      icon="ni ni-diamond"
    />
  </div> -->
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";
// import Settings from "../../components/Icon/Settings.vue";
// import Basket from "../../components/Icon/Basket.vue";
// import Box3d from "../../components/Icon/Box3d.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import Basket from "../../components/Icon/Basket.vue";
import Settings from "../../components/Icon/Settings.vue";
// import Document from "../../components/Icon/Document.vue";
// import Spaceship from "../../components/Icon/Spaceship.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";

import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCard,
    // SidenavCollapseItem,
    // Settings,
    // Basket,
    // Box3d,
    Shop,
    Office,
    Basket,
    Settings,
    // Document,
    // Spaceship,
    // CreditCard,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
