<template>
  <div
    class="container-fluid py-0 page-wrap d-flex justify-content-center h-100"
  >
    <div class="scan-wrap">
      <!-- STEP AUTH START -->
      <div v-if="activeStep == 'AUTH'" class="step-wrap auth">
        <div v-if="authStep == -1" class="content-top blank"></div>
        <div v-if="authStep == -9999" class="content-top auth-error">
          <div class="">
            <img src="../../assets/img/esim/usimstore_logo.png" />
          </div>
          <div
            class="auth-error-wrap d-flex flex-column justify-content-center align-items-center"
          >
            <div class="auth-error-image pt-5">
              <img src="../../assets/img/esim/ico-warning-2.png" />
            </div>
            <div class="auth-error-title fw-bold py-3">
              고객님의 주문 정보를 조회할 수 없습니다.
            </div>
            <div class="auth-error-desc py-3">
              불편을 드려 죄송합니다.<br />
              잠시 후 다시 시도해 주시기 바랍니다.<br /><br />
              지속적으로 접근이 되지 않을 시<br />
              유심스토어 고객센터로 연락하여 주시면,<br />
              고객님의 문제해결에 도움을 드리겠습니다.
            </div>
            <div class="auth-info-connect">
              <div class="connect-title pt-5">고객센터</div>
              <div class="connect-desc">
                <div class="connect-desc-item py-1">
                  <a href="https://pf.kakao.com/_CxdqxeV" target="_blnak">
                    <img src="../../assets/img/esim/ico-kakao.png" />
                    유심스토어
                  </a>
                </div>
                <div class="connect-desc-item py-1">
                  <a href="https://talk.naver.com/ct/w4v11d" target="_blnak">
                    <img src="../../assets/img/esim/ico-naver.png" />
                    유심스토어
                  </a>
                </div>
                <div class="connect-desc-item py-1">
                  <a href="tel://027378282" target="_blnak">
                    <img src="../../assets/img/esim/ico-phone.png" />
                    02-737-8282
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="authStep > -1" class="content-top normal">
          <div class="">
            <img src="../../assets/img/esim/usimstore_logo.png" />
          </div>

          <div v-if="authStep == 0" class="mb-4 auth-wrap auth-phone mt-4">
            <div class="title mb-4">
              <div class="auth-name mb-4">
                <span class="fw-bold">{{ authName }}</span> 고객님!
              </div>
              구매정보 확인을 위해<br />
              <span class="fw-bold">본인 인증</span>이 필요해요.<br />
              <span class="fw-bold">휴대폰 번호 뒷자리</span>를 입력해주세요.
            </div>
            <div class="d-flex justify-content-center">
              <img class="p-3" :src="getAuthPhoneCheckImg(1)" />
              <img class="p-3" :src="getAuthPhoneCheckImg(2)" />
              <img class="p-3" :src="getAuthPhoneCheckImg(3)" />
              <img class="p-3" :src="getAuthPhoneCheckImg(4)" />
            </div>
            <div class="number-pad-wrap">
              <div class="container text-center">
                <div class="row">
                  <div class="col-4 item-phone" @click="clickAuthPhone('1')">
                    1
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('2')">
                    2
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('3')">
                    3
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('4')">
                    4
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('5')">
                    5
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('6')">
                    6
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('7')">
                    7
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('8')">
                    8
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('9')">
                    9
                  </div>
                  <div class="col-4">&nbsp;</div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('0')">
                    0
                  </div>
                  <div class="col-4 item-phone" @click="clickAuthPhone('-1')">
                    <img src="../../assets/img/esim/ico_backspace.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          v-if="authStep == 0"
          class="bottom-button-wrap position-fixed bottom-0 w-100"
        >
          <button
            type="button"
            class="btn btn-primary w-100 mb-0"
            @click="checkAuthName()"
          >
            다음
          </button>
        </div> -->
      </div>
      <!-- STEP AUTH END -->

      <!-- STEP MAIN START -->
      <div v-if="activeStep == 'MAIN'" class="step-wrap main">
        <div class="step-infomation">
          <div
            class="info-wrap d-flex flex-column justify-content-center align-items-center"
          >
            <img class="my-3" src="../../assets/img/esim/usimstore_logo2.png" />
            <div class="title">eSIM 발급 정보를 입력해 주세요.</div>
            <!-- <div class="dday">발권 기한: D-7</div> -->
            <div class="detail-info text-center">
              <!-- {{ authName }} 고객님, 구매하신 eSIM 발급을 위하여<br />
              아래 정보를 입력해 주시면
              <span class="big">알림톡과 이메일로</span><br />
              <span class="big">eSIM을 발송해 드려요.</span> -->
              {{ authName }} 고객님, 구매하신 eSIM의 개통에 필요한<br />
              정보를 제출해 주시면, 지정하신 일자에 이용하실 수 있도록
              도와드리겠습니다.
            </div>
          </div>

          <div class=""><br /><br /></div>

          <Carousel
            ref="orderGoodsCarousel"
            :items-to-show="1.2"
            :wrap-around="false"
            :transition="500"
            :model-value="activeSlideIndex"
            @slide-start="sStart"
            @slide-end="sEnd"
          >
            <Slide v-for="(item, index) in orderGoodsData" :key="index">
              <div class="carousel__item">
                <div
                  class="d-flex item-top justify-content-between align-items-center"
                >
                  <!-- <div class="item-image me-3">
                    <img src="../../assets/img/esim/item_qr.png" />
                  </div> -->
                  <div class="item-info">
                    <div
                      class="item-name d-flex justify-content-end align-items-center"
                    >
                      <div class="number-badge">{{ index + 1 }}</div>
                      {{ item.mainTitle }}
                    </div>
                    <div class="item-info text-end">
                      {{ item.subTitle }}
                      <div
                        v-for="(opt, index2) in item.goodsOrderOptions"
                        :key="index2"
                      >
                        {{ opt }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-bottom d-flex flex-column text-start">
                  <div v-if="item.inputPassport" class="form-group mb-0">
                    <label
                      v-if="item.passportNumber == ''"
                      :class="{ 'is-invalid': item.passportNumberWarning }"
                      @click="setEditMode('PASSPORTINFO', index)"
                      >여권번호(여권정보)</label
                    >
                    <div class="input-group mb-0">
                      <input
                        v-model="item.passportNumber"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.passportNumberWarning }"
                        @click="setEditMode('PASSPORTINFO', index)"
                      />
                    </div>
                  </div>
                  <div v-if="item.inputPassport" class="form-group mb-0">
                    <label
                      v-if="item.passportName == ''"
                      :class="{ 'is-invalid': item.passportNameWarning }"
                      @click="setEditMode('PASSPORTINFO', index)"
                      >성명(여권정보)</label
                    >
                    <div class="input-group mb-0">
                      <input
                        v-model="item.passportName"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.passportNameWarning }"
                        @click="setEditMode('PASSPORTINFO', index)"
                      />
                    </div>
                  </div>
                  <div v-if="item.inputPassport" class="form-group mb-0">
                    <label
                      v-if="item.passportCountry == ''"
                      :class="{ 'is-invalid': item.passportCountryarning }"
                      @click="setEditMode('PASSPORTINFO', index)"
                      >국가(여권정보)</label
                    >
                    <div class="input-group mb-0">
                      <input
                        v-model="item.passportCountry"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.passportCountryarning }"
                        @click="setEditMode('PASSPORTINFO', index)"
                      />
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <label
                      v-if="getEmailAddress(index) == ''"
                      :class="{ 'is-invalid': item.emailWarning }"
                      @click="setEditMode('MAILPHONE', index)"
                      >이메일</label
                    >
                    <div class="input-group mb-0">
                      <input
                        :value="getEmailAddress(index)"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.emailWarning }"
                        @click="setEditMode('MAILPHONE', index)"
                      />
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <label
                      v-if="getPhoneNumber(index) == ''"
                      :class="{ 'is-invalid': item.phoneWarning }"
                      @click="setEditMode('MAILPHONE', index)"
                      >전화번호</label
                    >
                    <div class="input-group mb-0">
                      <input
                        :value="getPhoneNumber(index)"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.phoneWarning }"
                        @click="setEditMode('MAILPHONE', index)"
                      />
                    </div>
                  </div>
                  <div v-if="item.inputDate" class="form-group mb-0">
                    <label
                      v-if="item.actDate == ''"
                      :class="{ 'is-invalid': item.actDateWarning }"
                      @click="setEditMode('DATE', index)"
                      >개통희망일</label
                    >
                    <div class="input-group mb-0">
                      <input
                        v-model="item.actDate"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.actDateWarning }"
                        @click="setEditMode('DATE', index)"
                      />
                    </div>
                  </div>
                  <div v-if="item.inputEid" class="form-group mb-0">
                    <label
                      v-if="item.eid == ''"
                      :class="{ 'is-invalid': item.eidWarning }"
                      @click="setEditMode('PHONEINFO', index)"
                      >기기정보(EID)</label
                    >
                    <div class="input-group mb-0">
                      <input
                        v-model="item.eid"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.eidWarning }"
                        @click="setEditMode('PHONEINFO', index)"
                      />
                    </div>
                  </div>
                  <div v-if="item.inputImei" class="form-group mb-0">
                    <label
                      v-if="item.imei == ''"
                      :class="{ 'is-invalid': item.imeiWarning }"
                      @click="setEditMode('PHONEINFO', index)"
                      >기기정보(IMEI)</label
                    >
                    <div class="input-group mb-0">
                      <input
                        v-model="item.imei"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        readOnly
                        :class="{ 'is-invalid': item.imeiWarning }"
                        @click="setEditMode('PHONEINFO', index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <!-- <template #addons>
              <Pagination />
            </template> -->
          </Carousel>
          <div
            class="cs-pn-wrap d-flex justify-content-center align-items-center"
          >
            <div
              v-for="(item, index) in orderGoodsData"
              :key="index"
              class="pn-item d-flex justify-content-center align-items-center"
              :class="{ active: activeSlideIndex == index }"
              @click="setOrderGoodsSlide(index)"
            >
              <span v-if="activeSlideIndex == index">{{ index + 1 }}</span>
            </div>
            <!-- <div
              class="pn-item d-flex justify-content-center align-items-center"
            >
              <span>2</span>
            </div> -->
          </div>

          <div class="item-comment d-flex align-items-center">
            <div v-if="warningData.length == 0" class="info-mark-default">
              <img src="../../assets/img/esim/ico-exclamation-mark.png" />
              빈칸을 눌러 정보를 입력해주세요
            </div>
            <div v-if="warningData.length > 0" class="warning">
              <div
                v-for="(item, index) in warningData"
                :key="index"
                class="info-mark-warning"
              >
                <img
                  src="../../assets/img/esim/ico-exclamation-mark-warning.png"
                />
                {{ item }}
              </div>
            </div>
          </div>

          <div class="comment-wrap p-3">
            <div class="comment-title">주의사항</div>
            <ol>
              <li>
                사용하시는 기기가 eSIM이 지원되는지 확인해 주세요.<br />
                <span class="link" @click="deviceModal()"
                  >[eSIM 지원가능 여부 확인하기]</span
                >
              </li>
              <li>
                제출 전 환불을 원하시는 경우에는 구매하신 채널을 통하여 문의해
                주세요.
              </li>
              <li>제출 후 단순 변심에 의한 교환/환불은 어려워요.</li>
              <li>기타 문의사항은 고객센터로 문의해 주세요.</li>
            </ol>
          </div>
        </div>

        <div class="bottom-button-wrap">
          <div class="warning-text d-flex justify-content-center">
            <img src="../../assets/img/esim/ico-warning.png" />
            제출 후에는 교환/환불이 어려워요.
          </div>
          <button
            type="button"
            class="btn btn-primary w-100 mb-0"
            @click="applyEsim()"
          >
            제출하기
          </button>
        </div>
      </div>
      <!-- STEP MAIN END -->

      <!-- STEP MAILPHONE START -->
      <div v-if="activeStep == 'MAILPHONE'" class="step-wrap mailponine">
        <div class="step-infomation">
          <div class="navi-wrap d-flex align-items-center">
            <img
              src="../../assets/img/esim/ico-back.png"
              @click="activeStep = 'MAIN'"
            />
            <span @click="activeStep = 'MAIN'">기본정보</span>
          </div>
        </div>
        <div class="item-info-wrap my-3">
          <div
            class="d-flex item-top justify-content-between align-items-center"
          >
            <!-- <div class="item-image me-3">
              <img src="../../assets/img/esim/item_qr.png" />
            </div> -->
            <div class="item-info">
              <div
                class="item-name d-flex justify-content-end align-items-center"
              >
                <div class="number-badge">{{ activeSlideIndex + 1 }}</div>
                {{ editObject.mainTitle }}
              </div>
              <div class="item-info text-end">
                {{ editObject.subTitle }}
                <div
                  v-for="(opt, index2) in editObject.goodsOrderOptions"
                  :key="index2"
                >
                  {{ opt }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="step-infomation">
          <!-- <div class="navi-wrap d-flex align-items-center">
            <img
              src="../../assets/img/esim/ico-back.png"
              @click="activeStep = 'MAIN'"
            />
            기본정보
          </div> -->

          <div class="detail-info">
            eSIM을 수신할 이메일과 휴대전화 번호를 입력해주세요.<br />
            <span v-if="batchUpdateView" class="small"
              >*일괄적용 선택 시 한 명에게 전체 발송</span
            >
          </div>

          <div
            v-if="batchUpdateView"
            class="batch-check-wrap d-flex justify-content-end"
          >
            <div class="form-check">
              <input
                id="importCheck"
                v-model="batchUpdate1"
                class="form-check-input"
                type="checkbox"
              />
              <label class="custom-control-label" for="importCheck"
                >일괄적용</label
              >
            </div>
          </div>

          <div class="form-title">이메일</div>
          <div
            class="item-email mt-2 d-flex justify-content-between align-items-center"
          >
            <input
              v-model="editObject.inputEmail1"
              type="text"
              class="form-control"
              style="text-transform: lowercase; ime-mode: disabled"
            />
            @
            <select
              v-if="editObject.inputEmailSelect != '직접입력'"
              v-model="editObject.inputEmailSelect"
              class="form-control"
            >
              <option
                v-for="(item, index) in emailOptions"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
            <div
              v-if="editObject.inputEmailSelect == '직접입력'"
              class="input-group mb-0"
            >
              <input
                v-model="editObject.inputEmail2"
                type="text"
                class="form-control"
                style="text-transform: lowercase; ime-mode: disabled"
              />
              <button class="form-control pe-0" @click="emailCustomCancel">
                X
              </button>
            </div>
          </div>

          <div class="form-title">휴대전화번호</div>
          <div
            class="item-phone mt-2 d-flex justify-content-between align-items-center"
          >
            <input
              ref="phonenumber1"
              v-model="editObject.inputPhonenumber1"
              type="text"
              class="form-control"
              maxlength="3"
              readOnly
              @keypress="NumbersOnly"
              @input="checkF($event, 3, 'phonenumber2')"
            />
            -
            <input
              ref="phonenumber2"
              v-model="editObject.inputPhonenumber2"
              class="form-control"
              maxlength="4"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              @keypress="NumbersOnly"
              @input="checkF($event, 4, 'phonenumber3')"
            />
            -
            <input
              ref="phonenumber3"
              v-model="editObject.inputPhonenumber3"
              class="form-control"
              maxlength="4"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              @keypress="NumbersOnly"
            />
          </div>

          <div class="absolute-blank-area"></div>

          <div class="bottom-button-wrap b-white">
            <button
              type="button"
              class="btn btn-primary w-100 mb-0"
              @click="dataSetting('MAILPHONE')"
            >
              완료
            </button>
          </div>
        </div>
      </div>
      <!-- STEP MAILPHONE END -->

      <!-- STEP DATE START -->
      <div v-if="activeStep == 'DATE'" class="step-wrap date">
        <div class="step-infomation">
          <div class="navi-wrap d-flex align-items-center">
            <img
              src="../../assets/img/esim/ico-back.png"
              @click="activeStep = 'MAIN'"
            />
            <span @click="activeStep = 'MAIN'">개통희망일</span>
          </div>
        </div>

        <div class="item-info-wrap my-3">
          <div
            class="d-flex item-top justify-content-between align-items-center"
          >
            <!-- <div class="item-image me-3">
              <img src="../../assets/img/esim/item_qr.png" />
            </div> -->
            <div class="item-info">
              <div
                class="item-name d-flex justify-content-end align-items-center"
              >
                <div class="number-badge">{{ activeSlideIndex + 1 }}</div>
                {{ editObject.mainTitle }}
              </div>
              <div class="item-info text-end">
                {{ editObject.subTitle }}
                <div
                  v-for="(opt, index2) in editObject.goodsOrderOptions"
                  :key="index2"
                >
                  {{ opt }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="step-infomation">
          <div class="detail-info">
            요금제를 사용하려는 날을<br />
            <span class="big">현지 시간</span> 기준으로 선택해 주세요.<br />
            <span v-if="batchUpdateView" class="small"
              >*일괄적용 해제 시,서로 다른 날짜 기입 가능</span
            >
          </div>

          <div
            v-if="batchUpdateView"
            class="batch-check-wrap d-flex justify-content-end"
          >
            <div class="form-check">
              <input
                id="importCheck"
                v-model="batchUpdate2"
                class="form-check-input"
                type="checkbox"
              />
              <label class="custom-control-label" for="importCheck"
                >일괄적용</label
              >
            </div>
          </div>

          <!-- <div class="date-sub-title py-4">
            현재 베트남은 14시 00분 이예요. (UTC+7)
          </div> -->

          <div class="mt-4 d-flex justify-content-center">
            <datepicker
              v-model="editObject.inputDate"
              :inline="true"
              language="kr"
              :disabled-dates="disabledDates"
              :prevent-disable-date-selection="preventDisableDateSelection"
            ></datepicker>
          </div>

          <div class="absolute-blank-area"></div>

          <div class="bottom-button-wrap b-white">
            <button
              type="button"
              class="btn btn-primary w-100 mb-0"
              @click="dataSetting('DATE')"
            >
              완료
            </button>
          </div>
        </div>
      </div>
      <!-- STEP DATE END -->

      <!-- STEP PHONEINFO START -->
      <div v-if="activeStep == 'PHONEINFO'" class="step-wrap phoneinfo">
        <div class="step-infomation">
          <div class="navi-wrap d-flex align-items-center">
            <img
              src="../../assets/img/esim/ico-back.png"
              @click="activeStep = 'MAIN'"
            />
            <span @click="activeStep = 'MAIN'">기기정보</span>
          </div>
        </div>

        <div class="item-info-wrap my-3">
          <div
            class="d-flex item-top justify-content-between align-items-center"
          >
            <!-- <div class="item-image me-3">
              <img src="../../assets/img/esim/item_qr.png" />
            </div> -->
            <div class="item-info">
              <div
                class="item-name d-flex justify-content-end align-items-center"
              >
                <div class="number-badge">{{ activeSlideIndex + 1 }}</div>
                {{ editObject.mainTitle }}
              </div>
              <div class="item-info text-end">
                {{ editObject.subTitle }}
                <div
                  v-for="(opt, index2) in editObject.goodsOrderOptions"
                  :key="index2"
                >
                  {{ opt }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="step-infomation">
          <div class="detail-info">
            전화 앱 -> *#06#을 입력 후 스크린샷을 저장하여<br />
            이미지를 업로드하면 <b>IMEI・EID 값이 자동 입력 되어요.</b>
          </div>
          <div class="detail-info-2">
            <span class="question-mark me-1" @click="eidModal()">?</span>
            <span @click="eidModal()">상세설명</span>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <img
              id="scanimg1"
              class="scanimg"
              src="../../assets/img/esim/ico-upload.png"
              style="width: 181px; height: 45px; cursor: pointer"
              @click="imageUpload"
            />
          </div>
          <div class="ps-4 mt-4" style="font-size: 12px; color: #000000">
            *이미지 업로드 후 자동입력 되지 않으면, 각 코드를 직접 입력해
            주세요.
          </div>

          <div class="scan-infomation">
            <input
              ref="fileinput"
              type="file"
              name="image"
              accept="image/*"
              class="form-control"
              style="display: none"
              @change="onChangeInput"
            />
            <div v-if="editObject.inputEid" class="form-group mb-0 pt-3">
              <label>EID</label>
              <div class="input-group mb-0">
                <input
                  v-model="editObject.eid"
                  type="text"
                  class="form-control"
                  :class="eidValidClass"
                  placeholder=""
                  aria-label=""
                  maxlength="32"
                  @keypress="NumbersOnly"
                />
              </div>
              <div v-if="editObject.errorEid != ''" class="error-text">
                {{ editObject.errorEid }}
              </div>
            </div>
            <div v-if="editObject.inputImei" class="form-group mb-0 pt-3">
              <label>IMEI</label>
              <div class="input-group mb-0">
                <input
                  v-model="editObject.imei"
                  type="text"
                  class="form-control"
                  :class="imeiValidClass"
                  placeholder=""
                  aria-label=""
                  maxlength="15"
                  @keypress="NumbersOnly"
                />
              </div>
              <div v-if="editObject.errorImei != ''" class="error-text">
                {{ editObject.errorImei }}
              </div>
            </div>
          </div>

          <div class="absolute-blank-area"></div>

          <div class="bottom-button-wrap b-white">
            <button
              type="button"
              class="btn btn-primary w-100 mb-0"
              @click="dataSetting('PHONEINFO')"
            >
              완료
            </button>
          </div>
        </div>
      </div>
      <!-- STEP PHONEINFO END -->

      <!-- STEP PASSPORTINFO START -->
      <div v-if="activeStep == 'PASSPORTINFO'" class="step-wrap passportinfo">
        <div class="step-infomation">
          <div class="navi-wrap d-flex align-items-center">
            <img
              src="../../assets/img/esim/ico-back.png"
              @click="activeStep = 'MAIN'"
            />
            <span @click="activeStep = 'MAIN'">여권정보</span>
          </div>
        </div>

        <div class="item-info-wrap my-3">
          <div
            class="d-flex item-top justify-content-between align-items-center"
          >
            <!-- <div class="item-image me-3">
              <img src="../../assets/img/esim/item_qr.png" />
            </div> -->
            <div class="item-info">
              <div
                class="item-name d-flex justify-content-end align-items-center"
              >
                <div class="number-badge">{{ activeSlideIndex + 1 }}</div>
                {{ editObject.mainTitle }}
              </div>
              <div class="item-info text-end">
                {{ editObject.subTitle }}
                <div
                  v-for="(opt, index2) in editObject.goodsOrderOptions"
                  :key="index2"
                >
                  {{ opt }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="step-infomation">
          <div v-if="batchUpdateView" class="detail-info">
            <!-- 각자 eSIM을 받으시려면<br />
            일괄적용을 <span class="big">비활성화</span> 하시고 정보를
            적어주세요.<br />
            <span class="small">*일괄적용 선택 시 한 명에게 전체발송</span> -->
            ...여권번호 일괄적용 설명.. 필요함.....
          </div>

          <div
            v-if="batchUpdateView"
            class="batch-check-wrap d-flex justify-content-end"
          >
            <div class="form-check">
              <input
                id="importCheck"
                v-model="batchUpdate4"
                class="form-check-input"
                type="checkbox"
              />
              <label class="custom-control-label" for="importCheck"
                >일괄적용</label
              >
            </div>
          </div>

          <div class="passport-infomation">
            <div class="form-group mb-0 pt-3">
              <label>여권번호</label>
              <div class="input-group mb-0">
                <input
                  v-model="editObject.passportNumber"
                  type="text"
                  class="form-control"
                  placeholder="여권번호"
                  aria-label="여권번호"
                />
              </div>
              <div
                v-if="editObject.errorPassportNumber != ''"
                class="error-text"
              >
                {{ editObject.errorPassportNumber }}
              </div>
            </div>

            <div class="form-group mb-0 pt-3">
              <label>성명</label>
              <div class="input-group mb-0">
                <input
                  v-model="editObject.passportName"
                  type="text"
                  class="form-control"
                  placeholder="성명"
                  aria-label="성명"
                />
              </div>
              <div v-if="editObject.errorPassportName != ''" class="error-text">
                {{ editObject.errorPassportName }}
              </div>
            </div>

            <div class="form-group mb-0 pt-3">
              <label>국가</label>
              <div class="input-group mb-0">
                <input
                  v-model="editObject.passportCountry"
                  type="text"
                  class="form-control"
                  placeholder="국가"
                  aria-label="국가"
                />
              </div>
              <div
                v-if="editObject.errorpassportCountry != ''"
                class="error-text"
              >
                {{ editObject.errorpassportCountry }}
              </div>
            </div>
          </div>

          <div class="absolute-blank-area"></div>

          <div class="bottom-button-wrap b-white">
            <button
              type="button"
              class="btn btn-primary w-100 mb-0"
              @click="dataSetting('PASSPORTINFO')"
            >
              완료
            </button>
          </div>
        </div>
      </div>
      <!-- STEP PASSPORTINFO END -->

      <!-- STEP COMPLETE START -->
      <!-- <div v-if="activeStep == 'COMPLETE'" class="step-wrap complete">
        <div class="">
          <img src="../../assets/img/esim/usimstore_logo.png" />
        </div>

        <div
          class="info-wrap d-flex flex-column justify-content-center align-items-center p-0 mb-3"
        >
          <div class="title">eSIM 발급이 완료되었어요!</div>
        </div>

        <Carousel
          ref="orderGoodsCarouselComplete"
          :items-to-show="1.2"
          :wrap-around="false"
          :transition="500"
          @slide-start="sStartComplete"
          @slide-end="sEndComplete"
        >
          <Slide v-for="(item, index) in orderGoodsData" :key="index">
            <div class="carousel__item">
              <div
                class="d-flex item-top justify-content-between align-items-center"
              >
                <div class="item-info">
                  <div
                    class="item-name d-flex justify-content-end align-items-center"
                  >
                    <div class="number-badge">{{ index + 1 }}</div>
                    {{ item.mainTitle }}
                  </div>
                  <div class="item-info text-end">
                    {{ item.subTitle }}
                    <div
                      v-for="(opt, index2) in item.goodsOrderOptions"
                      :key="index2"
                    >
                      {{ opt }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-bottom d-flex flex-column text-start">
                <div class="form-group mb-0">
                  <label>이용기간</label>
                  <div class="input-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="이용기간"
                      aria-label="이용기간"
                      readOnly
                      :value="item.periodText"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label>설치시점</label>
                  <div class="input-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="설치시점"
                      aria-label="설치시점"
                      readOnly
                      :value="item.installText"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label>APN</label>
                  <div class="input-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="APN"
                      aria-label="APN"
                      readOnly
                      :value="item.apnText"
                    />
                  </div>
                </div>
                <a
                  :href="makeInstallUrl(item)"
                  target="_blank"
                  class="btn-complete-info mt-4 mb-2"
                >
                  설치방법
                </a>
                <div
                  class="btn-complete-info mb-4"
                  @click="completeModal(index)"
                >
                  입력정보
                </div>
              </div>
            </div>
          </Slide>
          <Slide :key="orderGoodsData.length">
            <div class="carousel__item">
              <div class="item-last">
                <div class="item-image my-5">
                  <img src="../../assets/img/esim/usimstore_logo3.png" />
                </div>
                <div class="item-title">고객센터</div>
                <div class="item-desc my-2">
                  영업시간 평일 09:00~18:00<br />
                  주말/공휴일 휴무 (점심 12:00~13:00)
                </div>
                <div class="item-consulting-wrap">
                  <div
                    class="item-consulting my-1 d-flex justify-content-start align-items-center"
                  >
                    <a href="https://pf.kakao.com/_CxdqxeV" target="_blnak">
                      <img src="../../assets/img/esim/ico-kakao.png" />
                      유심스토어
                    </a>
                  </div>
                  <div
                    class="item-consulting my-1 d-flex justify-content-start align-items-center"
                  >
                    <a href="https://talk.naver.com/ct/w4v11d" target="_blnak">
                      <img src="../../assets/img/esim/ico-naver.png" />
                      유심스토어
                    </a>
                  </div>
                  <div
                    class="item-consulting my-1 d-flex justify-content-start align-items-center"
                  >
                    <a href="tel://027378282" target="_blnak">
                      <img src="../../assets/img/esim/ico-phone.png" />
                      02-737-8282
                    </a>
                  </div>
                </div>
                <a
                  href="https://www.usimstore.com/guide/link/Troubleshooting.html "
                  target="_blank"
                  class="btn-consulting-info mt-4 mb-2 bg-white"
                >
                  안될 시 해결방법
                </a>
                <a
                  href="https://www.usimstore.com/guide/link/FAQ.html"
                  target="_blank"
                  class="btn-consulting-info mb-4 bg-white"
                >
                  자주 묻는 질문
                </a>
              </div>
            </div>
          </Slide>
        </Carousel>
        <div
          class="cs-pn-wrap d-flex justify-content-center align-items-center"
        >
          <div
            v-for="(item, index) in orderGoodsData"
            :key="index"
            class="pn-item d-flex justify-content-center align-items-center"
            :class="{ active: activeSlideCompleteIndex == index }"
            @click="setOrderGoodsSlideComplete(index)"
          >
            <span v-if="activeSlideCompleteIndex == index">{{
              index + 1
            }}</span>
          </div>
          <div
            class="pn-item d-flex justify-content-center align-items-center"
            :class="{
              active: activeSlideCompleteIndex == orderGoodsData.length,
            }"
            @click="setOrderGoodsSlideComplete(orderGoodsData.length)"
          >
            <span v-if="activeSlideCompleteIndex == orderGoodsData.length"
              >!</span
            >
          </div>
        </div>
      </div> -->
      <div v-if="activeStep == 'COMPLETE'" class="step-wrap complete">
        <div class="">
          <img src="../../assets/img/esim/usimstore_logo.png" />
        </div>

        <div
          class="info-wrap d-flex flex-column justify-content-center align-items-center p-0 mb-3"
        >
          <div class="title">제출완료!</div>
          <div class="title-sub">
            제출해 주신 정보로 eSIM 개통을 진행하겠습니다.<br />
            구매하신 eSIM에 대한 정보는 별도의<br />
            알림톡과 이메일로 안내드리겠습니다.
          </div>
        </div>

        <Carousel
          ref="orderGoodsCarouselComplete"
          :items-to-show="1.2"
          :wrap-around="false"
          :transition="500"
          @slide-start="sStartComplete"
          @slide-end="sEndComplete"
        >
          <Slide v-for="(item, index) in orderGoodsData" :key="index">
            <div class="carousel__item">
              <div
                class="d-flex item-top justify-content-between align-items-center"
              >
                <!-- <div class="item-image me-3">
                  <img src="../../assets/img/esim/item_qr.png" />
                </div> -->
                <div class="item-info">
                  <div
                    class="item-name d-flex justify-content-end align-items-center"
                  >
                    <div class="number-badge">{{ index + 1 }}</div>
                    {{ item.mainTitle }}
                  </div>
                  <div class="item-info text-end">
                    {{ item.subTitle }}
                    <div
                      v-for="(opt, index2) in item.goodsOrderOptions"
                      :key="index2"
                    >
                      {{ opt }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-bottom d-flex flex-column text-start">
                <div class="form-group mb-0">
                  <label>이용기간</label>
                  <div class="input-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="이용기간"
                      aria-label="이용기간"
                      readOnly
                      :value="item.periodText"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label>설치시점</label>
                  <div class="input-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="설치시점"
                      aria-label="설치시점"
                      readOnly
                      :value="item.installText"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label>APN</label>
                  <div class="input-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="APN"
                      aria-label="APN"
                      readOnly
                      :value="item.apnText"
                    />
                  </div>
                </div>
                <a
                  :href="makeInstallUrl(item)"
                  target="_blank"
                  class="btn-complete-info mt-4 mb-2"
                >
                  설치방법
                </a>
                <div
                  class="btn-complete-info mb-4"
                  @click="completeModal(index)"
                >
                  입력정보
                </div>
              </div>
            </div>
          </Slide>
          <Slide :key="orderGoodsData.length">
            <div class="carousel__item">
              <div class="item-last">
                <div class="item-image my-5">
                  <img src="../../assets/img/esim/usimstore_logo3.png" />
                </div>
                <div class="item-title">고객센터</div>
                <div class="item-desc my-2">
                  영업시간 평일 09:00~18:00<br />
                  주말/공휴일 휴무 (점심 12:00~13:00)
                </div>
                <div class="item-consulting-wrap">
                  <div
                    class="item-consulting my-1 d-flex justify-content-start align-items-center"
                  >
                    <a href="https://pf.kakao.com/_CxdqxeV" target="_blnak">
                      <img src="../../assets/img/esim/ico-kakao.png" />
                      유심스토어
                    </a>
                  </div>
                  <div
                    class="item-consulting my-1 d-flex justify-content-start align-items-center"
                  >
                    <a href="https://talk.naver.com/ct/w4v11d" target="_blnak">
                      <img src="../../assets/img/esim/ico-naver.png" />
                      유심스토어
                    </a>
                  </div>
                  <div
                    class="item-consulting my-1 d-flex justify-content-start align-items-center"
                  >
                    <a href="tel://027378282" target="_blnak">
                      <img src="../../assets/img/esim/ico-phone.png" />
                      02-737-8282
                    </a>
                  </div>
                </div>
                <a
                  href="https://www.usimstore.com/guide/link/Troubleshooting.html "
                  target="_blank"
                  class="btn-consulting-info mt-4 mb-2 bg-white"
                >
                  안될 시 해결방법
                </a>
                <a
                  href="https://www.usimstore.com/guide/link/FAQ.html"
                  target="_blank"
                  class="btn-consulting-info mb-4 bg-white"
                >
                  자주 묻는 질문
                </a>
              </div>
            </div>
          </Slide>
          <!-- <template #addons>
            <Pagination />
          </template> -->
        </Carousel>
        <div
          class="cs-pn-wrap d-flex justify-content-center align-items-center"
        >
          <div
            v-for="(item, index) in orderGoodsData"
            :key="index"
            class="pn-item d-flex justify-content-center align-items-center"
            :class="{ active: activeSlideCompleteIndex == index }"
            @click="setOrderGoodsSlideComplete(index)"
          >
            <span v-if="activeSlideCompleteIndex == index">{{
              index + 1
            }}</span>
          </div>
          <div
            class="pn-item d-flex justify-content-center align-items-center"
            :class="{
              active: activeSlideCompleteIndex == orderGoodsData.length,
            }"
            @click="setOrderGoodsSlideComplete(orderGoodsData.length)"
          >
            <span v-if="activeSlideCompleteIndex == orderGoodsData.length"
              >!</span
            >
          </div>
          <!-- <div
              class="pn-item d-flex justify-content-center align-items-center"
            >
              <span>2</span>
            </div> -->
        </div>
      </div>
      <!-- STEP COMPLETE END -->

      <!-- ESIM DEVICE MODAL START -->
      <div
        id="modal-device"
        class="modal fade modal-device"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-device"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="title m-2">eSIM 지원기기</div>
              <div class="line"></div>
              <div
                v-for="(item, index) in esimDeviceList"
                :key="index"
                class="device-wrap my-2"
              >
                <div class="division">{{ item.division }}</div>
                <div class="data">{{ item.data }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ESIM DEVICE MODAL END -->

      <!-- EID INFOMATION MODAL START -->
      <div
        id="modal-eid"
        class="modal fade modal-eid"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-eid"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body p-4">
              <span class="ico-close" data-bs-dismiss="modal">X</span>
              <div class="esim-info-wrap mt-4">
                <div class="title">
                  기본 전화앱에 *#06# 입력 후 스크린샷 저장!
                </div>
                <div class="desc">
                  T전화 등 기타 전화앱으로 실행 시 아래 항목이 나타나지 않아요
                </div>
                <img src="../../assets/img/esim/esim_infomation_1.png" />
                <img src="../../assets/img/esim/esim_infomation_2.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- EID INFOMATION MODAL END -->

      <!-- COMPLETE INFO MODAL START -->
      <div
        id="modal-complete"
        class="modal fade modal-complete"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-complete"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable"
          role="document"
          style="max-width: 600px; margin: auto"
        >
          <div class="modal-content">
            <div class="modal-body p-0">
              <span class="ico-close" data-bs-dismiss="modal"
                ><i class="fas fa-times" aria-hidden="true"></i
              ></span>
              <div class="card card-plain mt-3">
                <div class="card-body">
                  <div class="qr-image-wrap text-center">
                    <div v-if="completeInfo.esimType == 'LPA'">
                      <QRCodeVue3
                        ref="qrCodeImage"
                        myclass="my-qur"
                        :value="completeInfo.actCode"
                        :width="200"
                        :height="200"
                        file-ext="png"
                        :corners-square-options="{
                          type: 'square',
                          color: '#000000',
                        }"
                        :corners-dot-options="{
                          type: 'square',
                          color: '#000000',
                        }"
                        :dots-options="{
                          type: 'square',
                          color: '#000000',
                        }"
                      />
                    </div>
                    <div v-if="completeInfo.esimType == 'DISCOVERY'">
                      <QRCodeVue3
                        ref="qrCodeImage"
                        myclass="my-qur"
                        :value="completeInfo.defaultQrcode"
                        :width="200"
                        :height="200"
                        file-ext="png"
                        :corners-square-options="{
                          type: 'square',
                          color: '#000000',
                        }"
                        :corners-dot-options="{
                          type: 'square',
                          color: '#000000',
                        }"
                        :dots-options="{
                          type: 'square',
                          color: '#000000',
                        }"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      completeInfo.esimType == 'LPA' ||
                      completeInfo.esimType == 'DISCOVERY'
                    "
                    class="install-info mb-4"
                  >
                    <div class="subtitle">수동설치</div>
                    <div class="form-group mb-0">
                      <label>SM-DP+주소</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="SM-DP+주소"
                          aria-label="SM-DP+주소"
                          readOnly
                          :value="completeInfo.smdp"
                        />
                        <button
                          class="btn btn-outline-secondary mb-0 px-3"
                          @click="completeCopyData('smdp')"
                        >
                          <span class="btn-inner--icon"
                            ><i class="far fa-copy"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <label>아이폰활성화코드</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="활성화코드"
                          aria-label="활성화코드"
                          readOnly
                          :value="completeInfo.activatecodeIos"
                        />
                        <button
                          class="btn btn-outline-secondary mb-0 px-3"
                          @click="completeCopyData('activatecodeIos')"
                        >
                          <span class="btn-inner--icon"
                            ><i class="far fa-copy"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <label>안드로이드활성화코드</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="활성화코드"
                          aria-label="활성화코드"
                          readOnly
                          :value="completeInfo.activatecodeAos"
                        />
                        <button
                          class="btn btn-outline-secondary mb-0 px-3"
                          @click="completeCopyData('activatecodeAos')"
                        >
                          <span class="btn-inner--icon"
                            ><i class="far fa-copy"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="install-info">
                    <div class="subtitle">입력정보</div>
                    <!-- <h4 class="title text-center">[ 입력정보 ]</h4> -->
                    <div class="form-group mb-0">
                      <label>이메일</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="이메일"
                          aria-label="이메일"
                          readOnly
                          :value="completeInfo.email"
                        />
                        <!-- <button
                          class="btn btn-outline-secondary mb-0 px-3"
                          @click="completeCopyData('email')"
                        >
                          <span class="btn-inner--icon"
                            ><i class="far fa-copy"></i
                          ></span>
                        </button> -->
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <label>전화번호</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="전화번호"
                          aria-label="전화번호"
                          readOnly
                          :value="completeInfo.phoneNumber"
                        />
                        <!-- <button
                          class="btn btn-outline-secondary mb-0 px-3"
                          @click="completeCopyData('phoneNumber')"
                        >
                          <span class="btn-inner--icon"
                            ><i class="far fa-copy"></i
                          ></span>
                        </button> -->
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <label>개통희망일</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="개통희망일"
                          aria-label="개통희망일"
                          readOnly
                          :value="completeInfo.actDate"
                        />
                      </div>
                    </div>
                    <div v-if="completeInfo.imei != ''" class="form-group mb-0">
                      <label>IMEI</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="IMEI"
                          aria-label="IMEI"
                          readOnly
                          :value="completeInfo.imei"
                        />
                      </div>
                    </div>
                    <div v-if="completeInfo.eid != ''" class="form-group mb-0">
                      <label>{{
                        completeInfo.esimType == "LPA" ? "ICCID" : "EID"
                      }}</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="
                            completeInfo.esimType == 'LPA' ? 'ICCID' : 'EID'
                          "
                          :aria-label="
                            completeInfo.esimType == 'LPA' ? 'ICCID' : 'EID'
                          "
                          readOnly
                          :value="completeInfo.eid"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        completeInfo.passportNumber != '' &&
                        completeInfo.inputPassport
                      "
                      class="form-group mb-0"
                    >
                      <label>여권정보(여권번호)</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="여권번호"
                          aria-label="여권번호"
                          readOnly
                          :value="completeInfo.passportNumber"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        completeInfo.passportName != '' &&
                        completeInfo.inputPassport
                      "
                      class="form-group mb-0"
                    >
                      <label>여권정보(성명)</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="성명"
                          aria-label="성명"
                          readOnly
                          :value="completeInfo.passportName"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        completeInfo.passportCountry != '' &&
                        completeInfo.inputPassport
                      "
                      class="form-group mb-0"
                    >
                      <label>여권정보(국가)</label>
                      <div class="input-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="국가"
                          aria-label="국가"
                          readOnly
                          :value="completeInfo.passportCountry"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- COMPLETE INFO MODAL END -->

      <!-- PROGRESSBAR START -->
      <div
        v-if="progressView"
        class="progress-wrap d-flex justify-content-center align-items-center"
      >
        <div class="progress-box">
          <div id="loader-6" class="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <!-- PROGRESSBAR END -->
    </div>
    <metainfo>
      <template #title="{ content }">{{ content }}</template>
    </metainfo>
  </div>
</template>

<script>
import { createWorker } from "tesseract.js";
// import {
//     createWorker,
//     PSM,
//     OEM
// } from 'tesseract.js';
// const worker = createWorker({
//     logger: m => console.log(m),
// });

import { BarcodeDetector } from "barcode-detector";
import { mapMutations } from "vuex";
import Datepicker from "vuejs3-datepicker";

// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import moment from "moment";
import { Modal } from "bootstrap";

import { useMeta } from "vue-meta"; // import useMeta

import QRCodeVue3 from "qrcode-vue3";

import momenttz from "moment-timezone";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "AdminLists",
  components: {
    Datepicker,
    Carousel,
    Slide,
    QRCodeVue3,
    // VueQuagga
    // ImageBarcodeReader
    // BrowserCodeReader
  },
  setup() {
    // useMeta를 이용하여 title, meta 태그 등을 설정
    useMeta({
      "theme-color": "#E7F5FF",
    });
    useMeta({
      viewport:
        "initial-scale=1.0; maximum-scale=1.0; minimum-scale=1.0; user-scalable=no; width=device-width, user-scalable=no",
    });
  },
  data() {
    return {
      esimDeviceList: [],
      progressView: false,
      modalComplete: null,
      warningData: [],
      authStep: -1,
      od_id: "",
      authName: "",
      authPhone: "",
      activeSlideIndex: 0,
      activeSlideCompleteIndex: 0,

      mbId: "",

      disabledDates: {
        // to: new Date(), // Disable all dates up to specific date
        to: moment(
          momenttz.tz("Asia/Seoul").format("YYYY-MM-DD 23:59:59"),
          "YYYY-MM-DD HH:mm:ss"
        ).toDate(), // Disable all dates up to specific date
      },
      preventDisableDateSelection: true,

      batchUpdate1: false,
      batchUpdate2: false,
      batchUpdate3: false,
      batchUpdate4: false,

      orderGoodsData: [
        {
          ct_od_id: "",
          inputDate: true,
          inputImei: true,
          inputEid: false,
          inputPassport: false,
          mainTitle: "베트남 로컬 eSIM",
          subTitle: "",
          goodsOrderOptions: [
            "5일, 데이터 무제한",
            "통화/ 문자 수신 가능",
            "핫스팟 지원",
          ],
          email1: "",
          email2: "",
          emailSelect: "",
          phonenumber1: "",
          phonenumber2: "",
          phonenumber3: "",
          actDate: "",
          eid: "",
          imei: "",

          periodText: "개통희망일부터 5일간 사용가능",
          installText: "출국 전 설치 권장",
          apnText: "esimplay",

          smdp: "esimpaly.com",
          activatecodeIos: "abcdefg1234567890",
          activatecodeAos: "esimpaly.comabcdefg1234567890",

          emailWarning: false,
          phoneWarning: false,
          eidWarning: false,
          imeiWarning: false,
          actDateWarning: false,
          passportNumberWarning: false,
          passportNameWarning: false,
          passportCountryWarning: false,
        },
      ],

      emailOptions: [
        "naver.com",
        "gmail.com",
        "daum.net",
        "hanmail.net",
        "kakao.com",
        "nate.com",
        "직접입력",
      ],

      inputDate: "",

      activeClass: "js-active position-relative",
      activeStep: "AUTH",

      readerSize: {
        width: 640,
        height: 480,
      },
      detecteds: [],
      resultHtml: "TEST",
      testVal: "TTTT",
      resultCode: [],
      resultView: false,
      // worker : createWorker({logger: m => console.log(m),})
      eidData: [],
      imeiData: [],

      selectEid: "",
      selectImei: "",

      errorEid: "",
      errorImei: "",

      errorPassportNumber: "",
      errorPassportName: "",
      errorpassportCountry: "",

      editObject: {
        mainTitle: "",
        subTitle: "",
        goodsOrderOptions: [],

        inputEmail1: "",
        inputEmail2: "",
        inputEmailSelect: "naver.com",

        inputPhonenumber1: "010",
        inputPhonenumber2: "",
        inputPhonenumber3: "",

        inputDate: "",

        inputEid: true,
        inputImei: true,

        inputPassport: true,

        eid: "",
        imei: "",

        validEid: null,
        validImei: null,

        passportNumber: "",
        passportName: "",
        passportCountry: "",
      },

      completeInfo: {
        smdp: "",
        activatecodeIos: "",
        activatecodeAos: "",
        email: "",
        phoneNumber: "",
        actDate: "",
        eid: "",
        imei: "",
        passportNumber: "",
        passportName: "",
        passportCountry: "",
      },

      voucherLink: [],
      URL_VOUCHER: {},
    };
  },
  computed: {
    getResultHtml() {
      return this.resultHtml;
    },
    eidValidClass: function () {
      return {
        "is-valid": this.editObject.validEid == true,
        "is-invalid": this.editObject.validEid == false,
      };
    },
    imeiValidClass: function () {
      return {
        "is-valid": this.editObject.validImei == true,
        "is-invalid": this.editObject.validImei == false,
      };
    },
    getEmailAddress() {
      return (index) => {
        let emailAddress = "";

        const email1 = this.orderGoodsData[index].email1;
        const email2 = this.orderGoodsData[index].email2;
        const emailSelect = this.orderGoodsData[index].emailSelect;

        if (email1 != "" && emailSelect != "직접입력") {
          emailAddress = email1.trim() + "@" + emailSelect;
        } else if (email1 != "" && emailSelect == "직접입력") {
          emailAddress = email1.trim() + "@" + email2.trim();
        }

        return emailAddress;
      };
    },
    getPhoneNumber() {
      return (index) => {
        let phnoeNmber = "";

        const phonenumber1 = this.orderGoodsData[index].phonenumber1;
        const phonenumber2 = this.orderGoodsData[index].phonenumber2;
        const phonenumber3 = this.orderGoodsData[index].phonenumber3;

        if (phonenumber1 != "" && phonenumber2 != "" && phonenumber3 != "") {
          phnoeNmber = `${phonenumber1}-${phonenumber2}-${phonenumber3}`;
        }

        return phnoeNmber;
      };
    },
    getAuthPhoneCheckImg() {
      return (index) => {
        if (index <= this.authPhone.length) {
          return require("@/assets/img/esim/ico_phone_on.png");
        } else {
          return require("@/assets/img/esim/ico_phone_off.png");
        }
      };
    },
    makeInstallUrl() {
      return (item) => {
        console.log(item);
        const voucherTemplate = item.voucherTemplate;
        const itIdOpt = item.itIdOpt;
        const itIdOptLastChar = itIdOpt.substr(itIdOpt.length - 1, 1);

        if (voucherTemplate == "QR") {
          return itIdOptLastChar == "V"
            ? this.URL_VOUCHER.URL_INSTALL_QR_VOICE.link
            : this.URL_VOUCHER.URL_INSTALL_QR_DATA.link;
        } else if (voucherTemplate == "QR_CMLink") {
          return itIdOptLastChar == "V"
            ? this.URL_VOUCHER.URL_INSTALL_QR_VOICE.link
            : this.URL_VOUCHER.URL_INSTALL_QR_DATA.link;
        } else if (voucherTemplate == "REDEEM") {
          return itIdOptLastChar == "V"
            ? this.URL_VOUCHER.URL_INSTALL_REDEEM_VOICE.link
            : this.URL_VOUCHER.URL_INSTALL_REDEEM_DATA.link;
        } else if (voucherTemplate == "REDEEM_RNR") {
          return itIdOptLastChar == "V"
            ? this.URL_VOUCHER.URL_INSTALL_REDEEM_RNR_VOICE.link
            : this.URL_VOUCHER.URL_INSTALL_REDEEM_RNR_DATA.link;
        } else if (voucherTemplate == "ESIM_DISCOVERY") {
          return this.URL_VOUCHER.URL_INSTALL_EID_VOICE.link;
        }
        return "#";
      };
    },
    batchUpdateView() {
      return this.orderGoodsData.length > 1 ? true : false;
    },
  },
  watch: {
    activeStep(value) {
      if (value == "MAILPHONE" || value == "DATE" || value == "PHONEINFO") {
        const curObj = this.orderGoodsData[this.activeSlideIndex];

        this.editObject.mainTitle = curObj.mainTitle;
        this.editObject.subTitle = curObj.subTitle;
        this.editObject.goodsOrderOptions = curObj.goodsOrderOptions;

        this.editObject.inputEmail1 = curObj.email1;
        this.editObject.inputEmail2 = curObj.email2;
        this.editObject.inputEmailSelect = curObj.emailSelect;

        this.editObject.inputPhonenumber1 = "010"; //curObj.phonenumber1;
        this.editObject.inputPhonenumber2 = curObj.phonenumber2;
        this.editObject.inputPhonenumber3 = curObj.phonenumber3;

        this.editObject.inputDate = curObj.actDate;

        this.editObject.inputEid = curObj.inputEid;
        this.editObject.inputImei = curObj.inputImei;

        this.editObject.inputPassport = curObj.inputPassport;

        this.editObject.eid = curObj.eid;
        this.editObject.imei = curObj.imei;

        this.editObject.validEid = curObj.validEid;
        this.editObject.validImei = curObj.validImei;

        this.editObject.passportNumber = curObj.passportNumber;
        this.editObject.passportName = curObj.passportName;
        this.editObject.passportCountry = curObj.passportCountry;
      }
    },
    selectEid(value) {
      console.log("watch eid", value);
      this.inputEid = value;
    },
    selectImei(value) {
      console.log("watch imei", value);
      this.inputImei = value;
    },
    authStep(value) {
      if (value == 0) {
        window.addEventListener("keyup", this.authCodeKeyUp); // enter 클릭시 alert 창 닫기
      } else {
        window.removeEventListener("keyup", this.authCodeKeyUp);
      }
    },
    "editObject.eid": function (value) {
      this.editObject.errorEid = "";
      console.log(value.length);
      const len = value.length;
      if (len < 32) {
        this.editObject.errorEid = "* 32자리 숫자를 입력해주세요.";
        this.editObject.validEid = false;
      } else {
        if (!this.checkEid(value)) {
          this.editObject.errorEid = "* EID코드가 잘못되었습니다.";
          this.editObject.validEid = false;
        } else {
          this.errorEid = "";
          this.editObject.validEid = true;
        }
      }
    },
    "editObject.imei": function (value) {
      console.log(value.length);
      const len = value.length;
      if (len < 15) {
        this.editObject.errorImei = "* 15자리 숫자를 입력해주세요.";
        this.editObject.validImei = false;
      } else {
        if (!this.checkImei(value)) {
          this.editObject.errorImei = "* IMEI코드가 잘못되었습니다.";
          this.editObject.validImei = false;
        } else {
          this.editObject.errorImei = "";
          this.editObject.validImei = true;
        }
      }
    },
    "editObject.inputEmail1": function (value) {
      this.editObject.inputEmail1 = value.toLowerCase();
      this.editObject.inputEmail1 = this.editObject.inputEmail1.replace(
        /[ㄱ-ㅎ가-힣]/gi,
        ""
      );
    },
    "editObject.inputEmail2": function (value) {
      this.editObject.inputEmail2 = value.toLowerCase();
      this.editObject.inputEmail2 = this.editObject.inputEmail2.replace(
        /[ㄱ-ㅎ가-힣]/gi,
        ""
      );
    },
    authPhone(value) {
      if (value.length == 4) {
        this.checkAuth();
      }
    },
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");

    if (this.$route.query.id) {
      this.od_id = this.$route.query.id;
    }
  },
  mounted() {
    if (!this.od_id) {
      // alert("올바르지 않은 경로로 접속하였습니다.");
      this.authStep = -9999;
    } else {
      this.checkOrderId();
    }

    this.disabledDates.to = moment(
      momenttz.tz("Asia/Seoul").format("YYYY-MM-DD 23:59:59"),
      "YYYY-MM-DD HH:mm:ss"
    ).toDate();

    this.testFunction();
    // this.testOcr();

    // Get the currently used metainfo
    // const metadata = useActiveMeta();
    // console.log("metadata ");
    // console.log(metadata);

    this.getEsimDevice();
    this.getVoucherLink();
    console.log("@@@@@@@@");
    console.log(
      moment(
        momenttz.tz("Asia/Seoul").format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      ).toDate()
    );
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    returnValue(a) {
      console.log("sure", a);
    },
    logIt(data) {
      console.log("detected", data);
    },
    initInputfile() {
      this.$refs.refUploadFile.value = null;
    },
    async readFile(event) {
      // get File object from input tag
      const file = event.target.files[0];
      const fileName = file.name;

      this.fileName = fileName;

      this.imageFile = file;

      // declare FileReader, temp result
      // const reader = new FileReader();
      // let tmpResult = [];
      // const vm = this;

      // console.log(XLSX)

      // // if you use "this", don't use "function(e) {...}"
      // reader.onload = (e) => {
      //   console.log("reader onload");
      // };
      // reader.readAsArrayBuffer(file);
    },

    onDecode(result) {
      console.log("result", result);
    },
    onError(error) {
      console.log("ERROR ", error);
    },

    async onChangeInput(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.progressView = true;

      this.eidData = [];
      this.imeiData = [];

      this.selectEid = "";
      this.selectImei = "";

      this.editObject.eid = "";
      this.editObject.imei = "";

      await this.barcodeProcess(files[0]);
      await this.ocrProcess(files[0]);

      console.log("this.eidData", this.eidData.leng);
      console.log("this.imeiData", this.imeiData);

      if (this.eidData.length > 0) {
        this.editObject.eid = this.eidData[0];
      }

      if (this.imeiData.length > 0) {
        this.editObject.imei = this.imeiData[0];
      }

      this.progressView = false;

      if (this.eidData.length == 0 && this.imeiData.length == 0) {
        this.$swal({
          title: "코드없음.",
          html:
            "이미지 파일에서 필요한 코드를 찾지 못했습니다.<br>코드를 직접 입력하여 주세요.",
        });
      }
    },

    async processFile(e) {
      console.log(e);
      this.$refs.scanimg.src = e.target.result;
      this.resultHtml = "0000";

      this.resultCode = [];

      // you can keep blob or save blob to another position
      const blob = new Blob([e.target.result]);

      const imageFile = await fetch(
        "https://192.168.0.156:8080/img/scan.67a419cc.jpg"
      ).then((resp) => resp.blob());

      console.log(imageFile);
      console.log(blob);
    },

    async testBarcode() {
      const barcodeDetector = new BarcodeDetector({
        formats: ["code_128"],
      });

      const imageFile = await fetch(
        "https://192.168.0.156:8080/img/scan.67a419cc.jpg"
      ).then((resp) => resp.blob());

      barcodeDetector.detect(imageFile).then((r) => {
        console.log(r);
      });
    },

    dataURLToBlob(dataURL) {
      var BASE64_MARKER = ";base64,";

      if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(",");
        var contentType = parts[0].split(":")[1];
        var raw = decodeURIComponent(parts[1]);

        return new Blob([raw], { type: contentType });
      }

      var parts2 = dataURL.split(BASE64_MARKER);
      var contentType2 = parts2[0].split(":")[1];
      var raw2 = window.atob(parts2[1]);
      var rawLength2 = raw2.length;

      var uInt8Array = new Uint8Array(rawLength2);

      for (var i = 0; i < rawLength2; ++i) {
        uInt8Array[i] = raw2.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType2 });
    },

    async testOcr(img) {
      // const img = document.getElementById('scanimg');
      // console.log(img);
      // await worker.load();
      // await worker.loadLanguage('eng');
      // await worker.initialize('eng', OEM.LSTM_ONLY);
      // await worker.setParameters({
      //   tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
      // });
      // const { data: { text } } = await worker.recognize(img);
      // console.log(text);

      // const img = document.getElementById('scanimg2');
      console.log(img);

      // const worker = await createWorker('eng');
      // const ret = await worker.recognize('https://192.168.0.156:8080/img/scan.67a419cc.jpg');
      // console.log(ret.data);
      // const text_androind = ret.data.text
      // await worker.terminate();

      // console.log(text_androind);

      // const worker2 = await createWorker('eng');
      // const ret2 = await worker2.recognize('https://192.168.0.156:8080/img/scan_ios.7f59dac0.jpeg');
      // console.log(ret2.data.text);
      // const text_ios = ret2.data
      // await worker2.terminate();
      // console.log(text_ios);

      // const worker3 = await createWorker('eng');
      // const ret3 = await worker3.recognize('https://192.168.0.156:8080/img/scan_android_f.027fc607.jpeg');
      // console.log(ret3.data);
      // const text_androind_f = ret3.data
      // await worker3.terminate();
      // console.log(text_androind_f);

      const worker4 = await createWorker("eng");
      const ret4 = await worker4.recognize(img);
      console.log(ret4.data);
      let text = ret4.data.text;
      await worker4.terminate();
      console.log(text);

      text = text.replace(/\n\n/g, "-");
      text = text.replace(/\n/g, "-");
      text = text.replace(/ /g, "-");

      console.log(text);
      const reg_eid = RegExp(/EID-\d+(?:-\d+)?/, "g");
      console.log(text.match(reg_eid));
      const reg_imei1 = RegExp(/IMEI1?-\d+(?:-\d+)?/, "g");
      console.log(text.match(reg_imei1));
      const reg_imei2 = RegExp(/IMEI2-\d+(?:-\d+)?/, "g");
      console.log(text.match(reg_imei2));
    },

    // selectChangeEid(value) {
    //   console.log(value);
    // },
    // selectChangeImei(value) {
    //   console.log(value);
    // },

    async barcodeProcess(fileData) {
      const _this = this;
      // console.log("@")
      // console.log(_this)

      var reader2 = new FileReader();
      reader2.onload = function (e) {
        // console.log(e.target.result);
        // document.getElementById("display").src = e.target.result;
        // image editing
        // ...

        const dataURL = e.target.result;

        var BASE64_MARKER = ";base64,";
        var blob;
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
          var parts = dataURL.split(",");
          var contentType = parts[0].split(":")[1];
          var raw = decodeURIComponent(parts[1]);

          blob = new Blob([raw], { type: contentType });
        } else {
          var parts2 = dataURL.split(BASE64_MARKER);
          var contentType2 = parts2[0].split(":")[1];
          var raw2 = window.atob(parts2[1]);
          var rawLength2 = raw2.length;

          var uInt8Array = new Uint8Array(rawLength2);

          for (var i = 0; i < rawLength2; ++i) {
            uInt8Array[i] = raw2.charCodeAt(i);
          }

          blob = new Blob([uInt8Array], { type: contentType2 });
        }

        // var blob = _th.dataURLtoBlob(e.target.result);

        console.log("blob", blob);

        const barcodeDetector = new BarcodeDetector({
          formats: ["code_128", "code_39", "code_93", "ean_13"],
        });

        barcodeDetector.detect(blob).then((r) => {
          // console.log(_this);
          // const __th = _this;
          r.forEach((element) => {
            console.log(element);
            if (_this.checkEid(element.rawValue)) {
              _this.setCode("EID", element.rawValue);
            } else if (_this.checkImei(element.rawValue)) {
              _this.setCode("IMEI", element.rawValue);
            }
          });
        });
      };
      reader2.readAsDataURL(fileData);
    },

    async ocrProcess(fileData) {
      const worker = await createWorker("eng");
      const ret = await worker.recognize(fileData);
      console.log(ret.data);
      let text = ret.data.text;
      await worker.terminate();
      console.log(text);

      text = text.replace(/\n\n/g, "-");
      text = text.replace(/\n/g, "-");
      text = text.replace(/ /g, "-");

      console.log(text);
      const reg_eid = RegExp(/EID-\d+(?:-\d+)?/, "g");
      console.log(text.match(reg_eid));
      const rst_reg_eid = text.match(reg_eid);
      if (rst_reg_eid) {
        rst_reg_eid.forEach((element) => {
          let code = element.replace(/EID-/g, "");
          code = code.replace(/-/g, "");
          code = code.replace(/ /g, "");
          this.setCode("EID", code);
        });
      }

      const reg_imei = RegExp(/IMEI[1-9]?-\d+(?:-\d+)?/, "g");
      console.log(text.match(reg_imei));
      const rst_reg_imei = text.match(reg_imei);
      if (rst_reg_imei) {
        rst_reg_imei.forEach((element) => {
          let code = element.replace(/IMEI[1-9]?-/g, "");
          code = code.replace(/-/g, "");
          code = code.replace(/ /g, "");
          console.log(code);
          this.setCode("IMEI", code);
        });
      }

      // const reg_imei1 = RegExp(/IMEI1?-\d+(?:-\d+)?/,"g")
      // console.log(text.match(reg_imei1))
      // const rst_reg_imei1 = text.match(reg_imei1)
      // rst_reg_imei1.forEach(element => {
      //   let code = element.replace(/IMEI1-/g, "")
      //   code = code.replace(/-/g, "")
      //   code = code.replace(/ /g, "")
      //   this.setCode("IMEI", code)
      // });

      // const reg_imei2 = RegExp(/IMEI2-\d+(?:-\d+)?/,"g")
      // console.log(text.match(reg_imei2))
      // const rst_reg_imei2 = text.match(reg_imei2)
      // rst_reg_imei2.forEach(element => {
      //   let code = element.replace(/IMEI2-/g, "")
      //   code = code.replace(/-/g, "")
      //   code = code.replace(/ /g, "")
      //   this.setCode("IMEI", code)
      // });
    },

    setCode(codeType, code) {
      if (codeType == "EID") {
        if (this.checkEid(code)) {
          if (this.eidData.indexOf(code) == -1) {
            this.eidData.push(code);
          }
        }
      } else if (codeType == "IMEI") {
        if (this.checkImei(code)) {
          if (this.imeiData.indexOf(code) == -1) {
            this.imeiData.push(code);
          }
        }
      }
    },

    checkEid(code) {
      console.log("checkEid call", code);
      console.log(code.length);
      try {
        if (code.length == 32) {
          // eslint-disable-next-line no-undef
          if (BigInt(code)) {
            // eslint-disable-next-line no-undef
            const intCode = BigInt(code);
            console.log("intCode", intCode);
            const calc = intCode % 97n;
            console.log("calc", calc);
            if (calc == 1) {
              return true;
            }
          }
        }
      } catch (error) {
        console.log("checkEid Error", error);
      }

      return false;
    },

    checkImei(code) {
      const result = false;

      try {
        if (code.length == 15) {
          const nDigits = code.length;
          let nSum = 0;
          let isSecond = false;

          for (let i = nDigits - 1; i >= 0; i--) {
            let d = parseInt(code[i]);

            if (isSecond === true) {
              d = d * 2;
            }

            // 두 자리 숫자를 처리하기 위해 두 개의 숫자를 더합니다.
            nSum += Math.floor(d / 10);
            nSum += d % 10;

            isSecond = !isSecond;
          }

          const mod10 = nSum % 10;
          if (mod10 === 0) {
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.log("checkImei Error", error);
      }

      return result;
    },

    validateEid() {
      if (this.checkEid(this.inputEid)) {
        alert("검증완료!");
      } else {
        alert("EID 검증오류~~");
      }
    },

    validateImei() {
      if (this.checkImei(this.inputImei)) {
        alert("검증완료!");
      } else {
        alert("EID 검증오류~~");
      }
    },

    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    checkF(evt, length, target) {
      console.log("checkF CALL");
      evt = evt ? evt : window.event;
      console.log(evt.target.value);
      const val = evt.target.value;
      if (val.length == length) {
        this.$refs[target].focus();
      }
    },

    imageUpload() {
      console.log("imageUpload");
      console.log(this.$refs);
      this.$refs.fileinput.click();
    },

    sStart(obj) {
      console.log("slice start event");
      console.log(obj);
    },
    sEnd(obj) {
      console.log("slice end event");
      console.log(obj);
      this.activeSlideIndex = obj.currentSlideIndex;
    },
    sStartComplete(obj) {
      console.log("slice start event");
      console.log(obj);
    },
    sEndComplete(obj) {
      console.log("slice end event");
      console.log(obj);
      this.activeSlideCompleteIndex = obj.currentSlideIndex;
    },
    emailCustomCancel() {
      this.editObject.inputEmailSelect = "naver.com";
    },

    dataSetting(type) {
      if (type == "MAILPHONE") {
        if (this.editObject.inputEmail1 == "") {
          alert("이메일을 올바르게 입력해주세요.");
          return;
        }
        if (
          this.editObject.inputEmailSelect == "직접입력" &&
          this.editObject.inputEmail2 == ""
        ) {
          alert("이메일을 올바르게 입력해주세요.");
          return;
        }

        if (
          this.editObject.inputPhonenumber1 == "" ||
          this.editObject.inputPhonenumber2 == "" ||
          this.editObject.inputPhonenumber3 == ""
        ) {
          alert("휴대폰번호를 올바르게 입력해주세요.");
          return;
        }

        if (this.batchUpdate1) {
          this.orderGoodsData.forEach((element) => {
            element.email1 = this.editObject.inputEmail1;
            element.email2 = this.editObject.inputEmail2;
            element.emailSelect = this.editObject.inputEmailSelect;

            element.phonenumber1 = this.editObject.inputPhonenumber1;
            element.phonenumber2 = this.editObject.inputPhonenumber2;
            element.phonenumber3 = this.editObject.inputPhonenumber3;
          });
        } else {
          this.orderGoodsData[
            this.activeSlideIndex
          ].email1 = this.editObject.inputEmail1;
          this.orderGoodsData[
            this.activeSlideIndex
          ].email2 = this.editObject.inputEmail2;
          this.orderGoodsData[
            this.activeSlideIndex
          ].emailSelect = this.editObject.inputEmailSelect;

          this.orderGoodsData[
            this.activeSlideIndex
          ].phonenumber1 = this.editObject.inputPhonenumber1;
          this.orderGoodsData[
            this.activeSlideIndex
          ].phonenumber2 = this.editObject.inputPhonenumber2;
          this.orderGoodsData[
            this.activeSlideIndex
          ].phonenumber3 = this.editObject.inputPhonenumber3;
        }
      } else if (type == "DATE") {
        const inputDate = this.editObject.inputDate;

        if (inputDate == "") {
          alert("일자를 선택해주세요.");
          return;
        }
        console.log("date ", new Date(inputDate));
        console.log("moent ", moment(inputDate));
        console.log("moent ", moment(inputDate).format("YYYY-MM-DD"));

        if (this.batchUpdate2) {
          this.orderGoodsData.forEach((element) => {
            element.actDate = moment(inputDate).format("YYYY-MM-DD");
          });
        } else {
          this.orderGoodsData[this.activeSlideIndex].actDate = moment(
            inputDate
          ).format("YYYY-MM-DD");
        }
      } else if (type == "PHONEINFO") {
        if (this.editObject.inputEid) {
          if (!this.checkEid(this.editObject.eid)) {
            this.editObject.validEid = false;
          } else {
            this.editObject.validEid = true;
          }

          if (!this.editObject.validEid) {
            alert("EID정보를 확인해주세요.");
            return;
          }
        }

        if (this.editObject.inputImei) {
          if (!this.checkImei(this.editObject.imei)) {
            this.editObject.validImei = false;
          } else {
            this.editObject.validImei = true;
          }
          if (!this.editObject.validImei) {
            alert("IMEI정보를 확인해주세요.");
            return;
          }
        }

        const dataImei = this.editObject.imei;
        const dataEid = this.editObject.eid;

        const validImei = this.editObject.validImei;
        const validEid = this.editObject.validEid;

        if (this.batchUpdate3) {
          this.orderGoodsData.forEach((element) => {
            if (dataImei) {
              element.eid = dataEid;
              element.validEid = validEid;
            }
            if (dataEid) {
              element.imei = dataImei;
              element.validImei = validImei;
            }
          });
        } else {
          if (dataImei) {
            this.orderGoodsData[this.activeSlideIndex].imei = dataImei;
            this.orderGoodsData[this.activeSlideIndex].validImei = validImei;
          }
          if (dataEid) {
            this.orderGoodsData[this.activeSlideIndex].eid = dataEid;
            this.orderGoodsData[this.activeSlideIndex].validEid = validEid;
          }
        }
      } else if (type == "PASSPORTINFO") {
        if (!this.editObject.passportNumber) {
          this.errorPassportNumber = true;
          return;
        }
        if (!this.editObject.passportName) {
          this.errorPassportName = true;
          return;
        }
        if (!this.editObject.passportCountry) {
          this.errorpassportCountry = true;
          return;
        }

        const dataPassportNumber = this.editObject.passportNumber;
        const dataPassportName = this.editObject.passportName;
        const datapassportCountry = this.editObject.passportCountry;

        if (this.batchUpdate4) {
          this.orderGoodsData.forEach((element) => {
            element.passportNumber = dataPassportNumber;
            element.passportName = dataPassportName;
            element.passportCountry = datapassportCountry;
          });
        } else {
          this.orderGoodsData[
            this.activeSlideIndex
          ].passportNumber = dataPassportNumber;
          this.orderGoodsData[
            this.activeSlideIndex
          ].passportName = dataPassportName;
          this.orderGoodsData[
            this.activeSlideIndex
          ].passportCountry = datapassportCountry;
        }
      }

      this.activeStep = "MAIN";
    },

    viewInputEid() {
      return this.orderGoodsData[this.activeSlideIndex].inputEid;
    },

    viewInputImei() {
      return this.orderGoodsData[this.activeSlideIndex].inputImei;
    },

    completeModal(index) {
      // const idx = index;

      const modalData = this.orderGoodsData[index];
      console.log("modalData", modalData);
      this.completeInfo.smdp = modalData.smdp;
      this.completeInfo.activatecodeIos = modalData.activatecodeIos;
      this.completeInfo.activatecodeAos = modalData.activatecodeAos;

      this.completeInfo.email = modalData.email;
      this.completeInfo.phoneNumber = modalData.phonenumber;
      this.completeInfo.actDate = modalData.actDate;
      this.completeInfo.imei = modalData.imei;
      this.completeInfo.eid = modalData.eid;

      this.completeInfo.actCode = modalData.actCode;
      this.completeInfo.defaultQrcode = modalData.defaultQrcode;
      this.completeInfo.itemType = modalData.itemType;
      this.completeInfo.esimType = modalData.esimType;

      this.completeInfo.passportNumber = modalData.passportNumber;
      this.completeInfo.passportName = modalData.passportName;
      this.completeInfo.passportCountry = modalData.passportCountry;

      this.completeInfo.inputPassport = modalData.inputPassport;

      this.modalComplete = new Modal(document.getElementById("modal-complete"));
      this.modalComplete.show();
    },

    completeCopyData(kind) {
      const copyData = this.completeInfo[kind];
      navigator.clipboard.writeText(copyData);

      setTimeout(() => {
        alert("복사되었습니다.");
      }, 500);
    },

    completeModalClose() {
      console.log("completeModalClose CALL");
      // this.modalComplete = new Modal(document.getElementById("modal-complete"));
      this.modalComplete.hide();
    },

    checkAuthName() {
      if (this.authName == "") {
        this.$swal({
          title: "이름확인",
          text: "이름을 입력해주세요.",
        });
        return;
      }

      const param = {
        odId: this.od_id,
        orderName: this.authName,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/checkOrderName",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          const count = response.data.result[0].count;

          if (count == 0) {
            this.$swal({
              title: "인증정보 불일치",
              html: `<h6>입력한 이름은 구매 시 기재한 이름과 불일치해요.<br>
                확인 후 재인증 해주세요.<br>
                (스마트스토어에서 구매하셨다면  수신자 정보에 기재한 이름을 입력해 주세요.)</h6>`,
            });
          } else {
            this.authStep = 1;
          }
        });
    },

    getEsimDevice() {
      // var vm = this;
      const param = {
        searchIsActive: 1,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/esimdevice/getEsimDevice",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          const resultData = response.data.result;

          const result = resultData.reduce((acc, curr) => {
            // [1]
            const { device_division, device_name } = curr; // [2]
            if (acc[device_division]) acc[device_division].push(device_name);
            // [3]
            else acc[device_division] = [device_name]; // [4]
            return acc; // [5]
          }, {});

          console.log(result);

          const rData = [];
          for (var prop in result) {
            rData.push({
              division: prop,
              data: result[prop].join(", "),
            });
          }

          console.log(rData);

          this.esimDeviceList = rData;
        });
    },

    getVoucherLink() {
      // var vm = this;
      const param = {
        searchIsActive: 1,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/common/getVoucherLink",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          const resultData = response.data.result;

          resultData.forEach((element) => {
            this.URL_VOUCHER[element.link_code] = element;
          });

          // this.voucherLink = resultData;
        });
    },

    checkOrderId() {
      // var vm = this;
      const param = {
        odId: this.od_id,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/checkOrderId",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);
          if (response.data.result.length == 0) {
            this.authStep = -9999;
          } else {
            const count = response.data.result[0].count;

            this.authName = response.data.result[0].od_name;

            if (count == 0) {
              this.authStep = -9999;
            } else {
              this.authStep = 0;
            }
          }
        });
    },

    checkAuth() {
      if (this.authPhone == "") {
        this.$swal({
          title: "휴대폰번호 확인",
          html: `<h6>휴대폰번호 뒷자리 4자리를 입력해주세요.</h6>`,
        });
        return;
      }

      if (this.authPhone.length < 4) {
        this.$swal({
          title: "휴대폰번호 확인",
          html: `<h6>휴대폰번호 뒷자리 4자리를 입력해주세요.</h6>`,
        });
        return;
      }

      // var vm = this;
      const param = {
        odId: this.od_id,
        // orderName: this.authName,
        orderPhone: this.authPhone,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/checkOrderPhone",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          const count = response.data.result[0].count;

          if (count == 0) {
            this.$swal({
              title: "인증정보 불일치",
              html: `<h6>입력한 휴대폰 번호 뒷자리와 구매 시 기재한 번호가
                    불일치 해요. 확인 후 재인증 해주세요.<br>
                    (스마트스토어에서 구매하셨다면 수신자 정보에 기재한
                    번호를 입력해 주세요.)</h6>`,
            });
            this.authPhone = "";
          } else {
            window.removeEventListener("keyup", this.authCodeKeyUp);
            this.getUsimStoreOrder(this.od_id);
          }
        });
    },

    getUsimStoreOrder() {
      this.authStep = -1;

      // var vm = this;
      const param = {
        odId: this.od_id,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/getUsimStoreOrder",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          const result = response.data.result;

          this.orderGoodsData = [];

          if (result.length == 0) {
            this.$swal({
              title: "주문데이터 오류",
              text: "주문데이터에 오류가 있습니다.",
            });
            this.authPhone = "";
            return;
          }

          let errorCheck = false;

          result.forEach((item) => {
            if (item.st_id == 0) {
              this.$swal({
                title: "주문데이터 오류2",
                text: "주문데이터에 오류가 있습니다.",
              });

              this.authPhone = "";

              errorCheck = true;
              return;
            }

            this.mbId = item.mb_id;

            let email = "";
            let phonenumber = "";
            let actDate = "";
            let eid = "";
            let imei = "";

            // 발권완료인경우 등록된 데이터 변수에 셋팅
            if (item.es_status == "C") {
              email = item.ct_email;
              phonenumber = item.ct_user_phonenum;
              actDate = item.ct_openday;
              eid = item.ct_simcard;
              imei = item.ct_user_imei;
            }

            const itemObject = {
              ct_od_id: item.ct_od_id,
              inputDate: item.collect_date_at ? true : false,
              inputImei: item.collect_imei_at ? true : false,
              inputEid: item.collect_eid_at ? true : false,
              inputPassport: item.collect_passport_at ? true : false,
              mainTitle: item.main_title,
              subTitle: item.sub_title,
              goodsOrderOptions: [],
              email1: "",
              email2: "",
              emailSelect: "",
              phonenumber1: "",
              phonenumber2: "",
              phonenumber3: "",
              email,
              phonenumber,
              actDate,
              eid,
              imei,

              periodText: this.makePeriodText(item), //"개통희망일부터 5일간 사용가능",
              installText: item.install_point, // "출국 전 설치 권장",
              apnText: item.apn_text, // "esimplay",
              defaultQrcode: item.default_qrcode,
              itemType: item.item_type,
              esimType: item.esim_type,

              actCode: item.es_esim_code,
              smdp: this.getSmdp(item),
              activatecodeIos: this.getActivatecodeIos(item),
              activatecodeAos: this.getActivatecodeAos(item),

              emailWarning: false,
              phoneWarning: false,
              eidWarning: false,
              imeiWarning: false,
              actDateWarning: false,
              passportNumberWarning: false,
              passportNameWarning: false,
              passportCountryWarning: false,

              passportNumber: item.ct_user_passport,
              passportName: item.ct_user_ename,
              passportCountry: item.es_passport_country,

              itIdOpt: item.it_id_opt,
              voucherTemplate: item.voucher_template,
              // ctOption: item.ct_option,
              // ctOption2: item.ct_option2,
              // ctOption4: item.ct_option4,
              // dayStandard: item.day_standard,
            };

            const optionDays = this.getDays(item);

            if (optionDays) {
              itemObject.goodsOrderOptions.push(optionDays);
            }

            // if (item.ct_option) {
            //   itemObject.goodsOrderOptions.push(item.ct_option);
            // }

            // if (item.ct_option2) {
            //   itemObject.goodsOrderOptions.push(item.ct_option2);
            // }

            // if (item.ct_option3) {
            //   itemObject.goodsOrderOptions.push(item.ct_option3);
            // }

            // if (item.ct_option4) {
            //   itemObject.goodsOrderOptions.push(item.ct_option4);
            // }

            // if (item.ct_option5) {
            //   itemObject.goodsOrderOptions.push(item.ct_option5);
            // }

            this.orderGoodsData.push(itemObject);
          });

          if (!errorCheck) {
            if (result[0].es_status == "C") {
              this.activeStep = "COMPLETE";
            } else {
              this.activeStep = "MAIN";
            }
          }
        });
    },

    clickAuthPhone(number) {
      console.log("clickAuthPhone CALL");
      if (number != -1) {
        if (this.authPhone.length < 4) {
          this.authPhone += number + "";
        }
      } else {
        if (this.authPhone.length > 0) {
          this.authPhone = this.authPhone.substring(
            0,
            this.authPhone.length - 1
          );
        }
      }
    },

    checkData() {
      this.warningData = [];

      this.orderGoodsData.forEach((element, index) => {
        element.emailWarning = false;
        element.actDateWarning = false;
        element.phoneWarning = false;
        element.eidWarning = false;
        element.imeiWarning = false;

        element.passportNumberWarning = false;
        element.passportNameWarning = false;
        element.passportCountryWarning = false;

        if (this.getEmailAddress(index) == "") {
          element.emailWarning = true;
          this.warningData.push(
            `${index + 1}번째 상품의 이메일을 입력해주세요.`
          );
        }

        if (element.actDate == "") {
          element.actDateWarning = true;
          this.warningData.push(
            `${index + 1}번째 상품의 개통희망일을 입력해주세요.`
          );
        }

        if (this.getPhoneNumber(index) == "") {
          element.phoneWarning = true;
          this.warningData.push(
            `${index + 1}번째 상품의 휴대폰번호를 입력해주세요.`
          );
        }

        if (element.eid == "" && element.inputEid) {
          element.eidWarning = true;
          this.warningData.push(`${index + 1}번째 상품의 EID를 입력해주세요.`);
        }

        if (element.imei == "" && element.inputImei) {
          element.imeiWarning = true;
          this.warningData.push(`${index + 1}번째 상품의 IMEI를 입력해주세요.`);
        }

        if (element.passportNumber == "" && element.inputPassport) {
          element.passportNumberWarning = true;
          this.warningData.push(
            `${index + 1}번째 상품의 여권번호(여권정보)를 입력해주세요.`
          );
        }

        if (element.passportName == "" && element.inputPassport) {
          element.passportNameWarning = true;
          this.warningData.push(
            `${index + 1}번째 상품의 성명(여권정보)를 입력해주세요.`
          );
        }

        if (element.passportCountry == "" && element.inputPassport) {
          element.passportCountryWarning = true;
          this.warningData.push(
            `${index + 1}번째 상품의 국가(여권정보)를 입력해주세요.`
          );
        }
      });
    },

    applyEsim() {
      this.checkData();

      if (this.warningData.length == 0) {
        this.progressView = true;
        // var vm = this;
        this.orderGoodsData.forEach((element, index) => {
          element.email = this.getEmailAddress(index);
          element.phonenumber = this.getPhoneNumber(index);
        });
        const param = {
          odId: this.od_id,
          mbId: this.mbId,
          odName: this.authName,
          orderGoodsData: this.orderGoodsData,
        };

        this.$axios
          .post(
            process.env.VUE_APP_BACKEND_URL +
              "api/usimstore/applyUsimStoreOrder",
            param
          )
          .then((response) => {
            this.getUsimStoreOrder();

            this.activeStep = "COMPLETE";

            console.log(response.data.result);

            // const count = response.data.result[0].count;

            // if (count == 0) {
            //   this.authStep = -9999;
            // } else {
            //   this.authStep = 0;
            // }

            this.progressView = false;
          });
      }
    },

    setOrderGoodsSlide(index) {
      this.$refs.orderGoodsCarousel.slideTo(index);
    },

    setOrderGoodsSlideComplete(index) {
      this.$refs.orderGoodsCarouselComplete.slideTo(index);
    },

    selectInputDate(selectDate) {
      console.log("selectDate", selectDate);
      console.log("selectDate", selectDate.constructor.toString());
    },

    setEditMode(activeStep, idx) {
      if (this.activeSlideIndex == idx) {
        this.activeStep = activeStep;
      }
    },

    deviceModal() {
      this.modalDevice = new Modal(document.getElementById("modal-device"));
      this.modalDevice.show();
    },

    eidModal() {
      this.modalEid = new Modal(document.getElementById("modal-eid"));
      this.modalEid.show();
    },

    testFunction() {
      const text =
        " 2.미국·캐나다·멕시코 5 통화 무제한  10일 주의사항: 기타메모에 이심수신용 이메일 기재(아이폰전용)";
      const reg = RegExp(/(\d+일)+/, "g");
      console.log(text.match(reg));
    },

    makePeriodText(data) {
      let usedateText = "";

      if (data.open_point == "기재한 개통 희망일") {
        usedateText = "개통희망일부터 구매일수동안 사용";
      } else if (
        data.open_point == "설치한 직후" &&
        data.daily_standard == "사용직후24시간"
      ) {
        usedateText = "설치시점부터 24시간을 하루로 인정, 구매일수동안 사용";
      } else if (
        data.open_point == "설치한 직후" &&
        data.daily_standard == "기준지역자정"
      ) {
        usedateText = "설치일을 하루로 인정, 구매일수동안 사용";
      } else if (
        data.open_point == "이용국가 내 네트워크 망 연결 시" &&
        data.daily_standard == "사용직후24시간"
      ) {
        usedateText =
          "현지 통신망 연결시점부터 24시간을 하루로 인정, 구매일수동안 사용";
      } else if (
        data.open_point == "이용국가 내 네트워크 망 연결 시" &&
        data.daily_standard == "기준지역자정"
      ) {
        usedateText = "현지 통신망 연결일을 하루로 인정, 구매일수동안 사용";
      }

      return usedateText;
    },

    getQrSrc(item) {
      const qrSrc = item.es_esim_code
        ? `https://chart.googleapis.com/chart?cht=qr&chs=160x160&chl=${item.es_esim_code}`
        : "";

      return qrSrc;
    },

    getSmdp(item) {
      let smdp = "";
      if (item.esim_type == "DISCOVERY") {
        smdp = item.default_qrcode.split("$")[1];
      } else if (item.esim_type == "LPA") {
        smdp = item.es_esim_code.split("$")[1];
      }
      return smdp;
    },

    getActivatecodeIos(item) {
      let activatecodeIos = "";
      if (item.esim_type == "DISCOVERY") {
        activatecodeIos = "";
      } else if (item.esim_type == "LPA") {
        activatecodeIos = item.es_esim_code.split("$")[2];
      }
      return activatecodeIos;
    },

    getActivatecodeAos(item) {
      let activatecodeAos = "";
      if (item.esim_type == "DISCOVERY") {
        activatecodeAos = item.default_qrcode;
      } else if (item.esim_type == "LPA") {
        activatecodeAos = item.es_esim_code;
      }
      return activatecodeAos;
    },

    authCodeKeyUp(event) {
      console.log(event.key);
      if (event.key == "Backspace" || event.key == "Delete") {
        if (this.authPhone.length > 0) {
          const aryAuthPhone = this.authPhone.split("");
          console.log(aryAuthPhone);
          aryAuthPhone.pop();
          console.log(aryAuthPhone);
          this.authPhone = aryAuthPhone.join();
          console.log(this.authPhone);
        }
      } else {
        const keyValue = parseInt(event.key);

        if (Number.isInteger(keyValue)) {
          if (this.authPhone.length >= 0 && this.authPhone.length < 4) {
            this.authPhone += keyValue.toString();
          }
        }
      }
    },

    getDays(item) {
      const reg = RegExp(/(\d+일)+/, "g");

      const subTitle = item.sub_title;

      const subTitleRegMatch = subTitle.match(reg);

      if (subTitleRegMatch != null) {
        console.log("@?@?");
        return "";
      }

      const ctOption = item.ct_option;
      const ctOption2 = item.ct_option2;
      const ctOption4 = item.ct_option4;
      const itIdOpt = item.it_id_opt;
      let day = "";

      const ctOptionRegMatch = ctOption.match(reg);
      const ctOption2RegMatch = ctOption2.match(reg);
      const ctOption4RegMatch = ctOption4.match(reg);

      const itIdOptAry = itIdOpt.split("_");
      let itIdOptTarget;
      let itIdOptRegMatch;
      if (itIdOptAry.length > 1) {
        itIdOptTarget = itIdOptAry.length == 2 ? itIdOptAry[0] : itIdOptAry[1];
        itIdOptRegMatch = itIdOptTarget.substr(itIdOptTarget.length - 4, 3);
      }

      if (ctOption2RegMatch != null) {
        day = ctOption2RegMatch[0];
      } else if (ctOption4RegMatch != null) {
        day = ctOption4RegMatch[0];
      } else if (ctOptionRegMatch != null) {
        day = ctOptionRegMatch[0];
      } else if (itIdOptRegMatch != null) {
        day = itIdOptRegMatch;
      }

      return day;
    },
  },
};
</script>
<style lang="scss">
body {
  font-family: "Pretendard";
}
.swal2-container {
  font-family: "Pretendard" !important;
  font-weight: normal !important;
  h6 {
    font-weight: normal !important;
  }
}
#app {
  height: 100%;
}
.vuejs3-datepicker__calendar-topbar {
  display: none;
}
</style>
<style lang="scss" scoped>
.form-group {
  label {
    background: #ffffff;
    padding: 0 0.2rem;
    margin-left: 0.5rem;
  }
}
.error-text {
  color: red;
  padding: 0 5px;
  font-size: 0.8rem;
}

.bottom-button-wrap {
  background: #f5f5f5;
  padding: 10px 10px 30px 10px;
  margin: 0 auto;
  max-width: 500px;
  &.b-white {
    background: #ffffff;
    padding: 15px 0;
  }
  .warning-text {
    font-size: 19px;
    color: #1e1e1e;
    margin-bottom: 10px;
    img {
      width: 34px;
      height: 31px;
      margin-right: 5px;
    }
  }
  button {
    background: #0d99ff;
    height: 64px;
    font-size: 22px;
    color: #ffffff;
  }
}

.page-wrap {
  font-family: "Pretendard";
  background: #ffffff;
  padding: 0;
  .scan-wrap {
    max-width: 500px;
    width: 100%;
    .info-wrap {
      padding: 70px 0 0 0;
      .text {
        font-size: 16px;
        font-weight: bold;
        color: #1e1e1e;
        padding-bottom: 15px;
      }
      .label-limit {
        display: inline-block;
        background: #ffffff;
        color: #000000;
        border-radius: 10px;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 15px;
      }

      .title {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        color: #000000;
      }
      .title-sub {
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px;
        color: #000000;
        text-align: center;
      }
      .dday {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
      }
    }

    .cnt-info {
      margin-top: 15px;
      padding: 15px;
      font-size: 20px;
      font-weight: medium;
      color: #000000;
      .text-blue {
        color: #0d99ff;
        font-weight: bold;
      }
    }

    .order-item-wrap {
      .order-item-card {
        padding: 15px;
        background: #ffffff;
        border-radius: 8px;

        .item-name {
          font-size: 20px;
          font-weight: bold;
        }

        .item-info {
          font-size: 16px;
        }

        .item-image {
        }

        .item-email {
          input,
          select {
            width: 47%;
            font-size: 18px;
          }
        }

        .item-phone {
          input {
            font-size: 18px;
            width: 31%;
          }
        }
      }
    }

    .comment-wrap {
      font-size: 14px;
      .comment-title {
        font-weight: bold;
      }
      .link {
        font-size: 12px;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .date-title {
      font-size: 18px;
    }

    .date-sub-title {
      font-size: 14px;
    }
  }

  .bottom-space {
    height: 80px;
  }
}

.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1);
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: #e7f5ff;
  color: #000;
  font-size: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  filter: drop-shadow(-5px -3px 10px rgba(0, 0, 0, 0.1));

  .item-top {
    padding: 15px;

    .item-name {
      font-size: 20px;
      font-weight: bold;

      .number-badge {
        background-color: #006ffd;
        color: #ffffff;
        font-size: 14px;
        margin-right: 5px;
        padding: 10px;
        line-height: 10px;
      }
    }

    .item-info {
      font-size: 16px;
      line-height: 1.2;
    }

    .item-image {
    }
  }

  .item-bottom {
    margin: 5px;
    background-color: #ffffff;
    padding: 20px 15px;
  }

  .item-last {
    .item-image {
    }
    .item-title {
      font-size: 16px;
      color: #000000;
      font-weight: bold;
    }
    .item-desc {
      font-size: 12px;
      color: #000000;
    }
    .item-consulting-wrap {
      .item-consulting {
        padding-left: 44px;
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        img {
          margin-right: 5px;
        }
      }
    }
  }
  .btn-consulting-info {
    margin-left: 25px;
    margin-right: 25px;
    display: block;
    border: 1px solid #8c8c8c;
    color: #8c8c8c;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    line-height: 1.8;
    cursor: pointer;
  }
}

.item-comment {
  font-weight: bold;
  font-size: 13px;
  color: #999999;
  img {
    margin-right: 5px;
  }
  .warning {
    color: #f24822;
  }
}

.item-info-wrap {
  background-color: #e7f5ff;
  color: #000;
  font-size: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .item-top {
    padding: 15px;

    .item-name {
      font-size: 20px;
      font-weight: bold;

      .number-badge {
        background-color: #006ffd;
        color: #ffffff;
        font-size: 14px;
        margin-right: 5px;
        padding: 10px;
        line-height: 10px;
      }
    }

    .item-info {
      font-size: 16px;
      line-height: 1.2;
    }

    .item-image {
    }
  }
}

.navi-wrap {
  color: #8c8c8c;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 15px 0px 15px;
  img {
    margin-right: 10px;
    width: 12px;
    height: 17px;
    cursor: pointer;
  }
  span {
    cursor: pointer;
  }
}

.detail-info {
  font-size: 14px;
  color: #000000;
  .big {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
  .small {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
}

.detail-info-2 {
  font-size: 14px;
  color: #777777;
  text-align: right;
  cursor: pointer;
  .question-mark {
    color: #ffffff;
    background: #777777;
    border-radius: 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    line-height: 15px;
  }
}

.batch-check-wrap {
  margin: 10px 0;
  .custom-control-label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
    cursor: pointer;
  }
}

.form-title {
  font-size: 16px;
  color: #1e1e1e;
  margin-top: 10px;
}

.item-email {
  input,
  select,
  .input-group {
    width: 47%;
    font-size: 18px;
  }
}

.item-phone {
  input {
    font-size: 18px;
    width: 31%;
  }
}

.absolute-blank-area {
  height: 50px;
}

.step-wrap {
  .step-infomation {
    padding: 0 15px;
  }

  .form-group {
    position: relative;
    label {
      cursor: pointer;
      position: absolute;
      top: 4px;
      z-index: 10;
      font-size: 13px;
      font-weight: normal;
      color: #8c8c8c;
    }
    margin-top: 15px;
    .form-control[readonly] {
      cursor: pointer;
      background: #ffffff;
    }
  }

  &.main {
    .form-control[readonly] {
      background: #ffffff;
    }

    background: rgb(231, 245, 255);
    background: linear-gradient(
      180deg,
      rgba(231, 245, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%
    );
  }

  &.complete {
    padding: 15px;

    .title {
      color: #1e1e1e;
    }
    .form-control[readonly] {
      background: #ffffff;
    }

    background: rgb(231, 245, 255);
    background: linear-gradient(
      180deg,
      rgba(231, 245, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%
    );

    .form-group {
      label {
        position: absolute;
        top: -9px;
        z-index: 10;
        font-size: 12px;
        font-weight: bold;
        color: #858585;
      }
    }
  }

  &.auth {
    height: 100%;
    background: rgb(231, 245, 255);
    background: linear-gradient(
      180deg,
      rgba(231, 245, 255, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );

    .content-top {
      padding: 15px;

      &.blank {
      }

      &.auth-error {
        .auth-error-title {
          font-size: 20px;
          text-align: center;
          color: #333333;
          padding: 0 35px;
        }
        .auth-error-desc {
          font-size: 15px;
          color: #333333;
          line-height: 1.3;
        }

        .auth-info-connect {
          color: #555555;
          font-size: 20px;
          .connect-title {
            font-weight: bold;
          }
          .connect-desc {
            .connect-desc-item {
            }
          }
        }
      }

      &.normal {
        .desc {
          font-size: 16px;
          color: #333333;
          padding-left: 22px;
          margin-top: 24px;
          .point {
            font-size: 20px;
          }
        }

        .auth-wrap {
          padding: 0 33px;

          .title {
            color: #333333;
            font-size: 16px;
            .auth-name {
              font-size: 22px;
            }
          }

          /*&.auth-name {
            .auth-input {
              padding: 0 30px;
              input {
                background: transparent;
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid #333333;
              }
            }
          } */

          &.auth-phone {
            .number-pad-wrap {
              font-size: 22px;
              .item-phone {
                cursor: pointer;
              }
              .col-4 {
                padding: 20px 0;
              }
            }
          }
        }
      }
    }
  }
}

.btn-complete-info {
  border: 1px solid #8c8c8c;
  color: #8c8c8c;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  line-height: 1.8;
  cursor: pointer;
}

.modal-complete {
  .subtitle {
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  .subtitle::before,
  .subtitle::after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  .subtitle::before {
    right: 0.5em;
    margin-left: -50%;
  }

  .subtitle::after {
    left: 0.5em;
    margin-right: -50%;
  }

  .form-group {
    position: relative;
    label {
      position: absolute;
      top: -9px;
      z-index: 10;
      font-size: 12px;
      font-weight: bold;
      color: #858585;
    }
    margin-top: 15px;
    .form-control[readonly] {
      background: #ffffff;
    }
    .btn {
      border-left: 0;
      border-color: #d2d6da;
    }
  }
}

.cs-pn-wrap {
  .pn-item {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #8c8c8c;

    margin: 0 2px;

    cursor: pointer;

    &.active {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: #0d99ff;
      color: #ffffff;
    }
  }
}

.ico-close {
  color: #999999;
  font-size: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999999;
  cursor: pointer;
}

/* PROGRESS */
.progress-wrap {
  width: 100%;
  height: 100%;
  position: sticky;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 999999;
}
#loader-6 {
  margin: 50px;
}

#loader-6 span {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: #006ffd;
  margin: 1px;
}

#loader-6 span:nth-child(1) {
  animation: grow 1s ease-in-out infinite;
}

#loader-6 span:nth-child(2) {
  animation: grow 1s ease-in-out 0.15s infinite;
}

#loader-6 span:nth-child(3) {
  animation: grow 1s ease-in-out 0.3s infinite;
}

#loader-6 span:nth-child(4) {
  animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}

.modal-device {
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
  }
  .line {
    height: 1px;
    background: #dddddd;
  }
  .device-wrap {
    .division {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
    }
    .data {
      font-size: 15px;
      color: #000000;
    }
  }
}

.modal-eid {
  .ico-close {
    font-size: 20px;
    top: 15px;
    right: 25px;
  }
  .esim-info-wrap {
    text-align: center;
    .title {
      font-size: 11px;
      font-weight: bold;
      color: #000000;
    }
    .desc {
      font-size: 9px;
      color: #000000;
    }
  }
}

.form-group {
  label {
    &.is-invalid {
      color: #f24822;
      font-weight: bold;
    }
  }
}
</style>
