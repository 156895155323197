import { createRouter, createWebHistory } from "vue-router";
import Eid from "../views/submit/eid.vue";

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/submit/eid",
  },
  {
    path: "/submit/eid",
    name: "Submit EID",
    component: Eid,
  },
  {
    path: "/:catchAll(.*)*",
    name: "catchALL",
    redirect: "/submit/eid",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  console.log(cookies.get("token"));
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 이 라우트는 인증이 필요하며 로그인 한 경우 확인하십시오.
    // 그렇지 않은 경우 로그인 페이지로 리디렉션하십시오.
    if (!cookies.get("token")) {
      next({
        path: "/authentication/signin/cover",
      });
    } else {
      next();
    }
  } else {
    next(); // 반드시 next()를 호출하십시오!
  }
});

export default router;
